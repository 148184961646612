import { messageTip, notificationTip, notificationTipTime } from './messageTip.js'

// 获取随机图片
export function onImageSwitch() {
    let randomNum = Math.floor(Math.random() * 37) + 1;
    let newImageUrl = "https://data-1310058445.cos.ap-guangzhou.myqcloud.com/bg-image/image_" + randomNum + ".jpg";

    return newImageUrl;
}

export async function copyText(text) {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    notificationTipTime("", "复制成功", "success", 1500);
}
