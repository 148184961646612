<template>
  <div class="bodyContent" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
    <div class="main-con">
      <div class="search-con">
        <div class="search">
          <div class="search-main">
            <div class="search-logo">
              <img v-for="(engine, index) in searchEngine" :key="engine.id" :src="engine.src" class="logo-item"
                :alt="engine.alt" v-show="currentEnginx.selectEnginxId === engine.id" @click="SwitchSearchEngine(engine)">
            </div>
            <input type="text" class="searchInput" name="search" id="searchInput" placeholder="请输入搜索内容"
              v-model="searchVal" @keydown.enter="onEnter()" autocomplete="off">
            <span class="imgCon" @click="onEnterSearchImage()">
              <img src="../assets/images/svg/search-image.svg" alt="以图搜图">
            </span>
          </div>
          <div class="search-image-switch">
            <span class="imgCon" @click="onImageSwitch()">
              <img src="../assets/images/svg/image-switch.svg" alt="以图搜图">
            </span>
          </div>
        </div>
      </div>
      <div class="con">
        1
        <!-- <div class="conItem often">
          <span>常用</span>
          <ContextMenu :old_data="data.common" :type="types[0]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div>
        <div class="conItem devel">
          <span>开发</span>
          <ContextMenu :old_data="data.development" :type="types[1]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div>
        <div class="conItem ai">
          <span>人工智能(部署|资源|资讯|工具)</span>
          <ContextMenu :old_data="data.artificial_Intelligence" :type="types[2]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div>
        <div class="conItem design">
          <span>设计(SVG|Font|Color|免扣PNG|工具|平台)</span>
          <ContextMenu :old_data="data.design" :type="types[3]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div>
        <div class="conItem life">
          <span>生活</span>
          <ContextMenu :old_data="data.lifestyle" :type="types[4]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div>
        <div class="conItem game">
          <span>游戏</span>
          <ContextMenu :old_data="data.game" :type="types[5]" @update="handleUpdate"
            @sendDataToParent_AlertBox="handleAlert" />
        </div> -->
      </div>

    </div>
  </div>
</template>

<script>
import Google from "../assets/images/svg/Google.svg"
import biying from "../assets/images/svg/biying.svg"
import Baidu from "../assets/images/svg/Baidu.svg"
import Bilibili from "../assets/images/svg/Bilibili.svg"


// import { doGet } from '../http/httpRequest.js'


export default {
  name: 'QuickContentView',
  components: {
    // ContextMenu,
  },
  data() {
    return {
      types: [
        "common",
        "development",
        "artificial_Intelligence",
        "design",
        "lifestyle",
        "game"
      ],
      searchVal: "",
      data: {
        common: [],
        development: [],
        artificial_Intelligence: [],
        design: [],
        lifestyle: [],
        game: []
      },
      classMapping: {
        'common': 'often',
        'development': 'devel',
        'artificial_Intelligence': 'ai',
        'design': 'design',
        'lifestyle': 'life',
        'game': 'game'
      },
      colors: ['#66bb6a', '#7da4f1', '#eff17d', '#b77df1', '#26a69a', '#a72726'],
      colorIndex: 0,
      searchEngine: [
        {
          "id": 0,
          "src": Baidu,
          "alt": "Baidu",
          "site": "https://www.baidu.com/s?wd=",
        },
        {
          "id": 1,
          "src": Bilibili,
          "alt": "Bilibili",
          "site": "https://search.bilibili.com/all?keyword=",
        },
        {
          "id": 2,
          "src": Google,
          "alt": "Google",
          "site": "https://www.google.com/search?q=",
        },
        {
          "id": 3,
          "src": biying,
          "alt": "biying",
          "site": "https://www.bing.com/search?q=",
        }
      ],
      currentEnginx: {
        "selectEnginxId": 0,
        "selectEnginxSite": "https://www.baidu.com/s?wd="
      },
      currentIndex: 0,
      showAlert: false,
      bgImage: "https://data-1310058445.cos.ap-guangzhou.myqcloud.com/bg-image/image_2.jpg"
    }
  },
  mounted() {
    // this.onImageSwitch();
    this.getTabDataList()
    console.log("QuickContentView mounted");
  },
  methods: {
    getTabDataList() {
      doGet("/api/tab/list", {})
        .then(response => {
          console.log("response.data", response.data.data);
          this.data = response.data.data
          // Set up interval to update remaining time every second
          this.intervalId = setInterval(() => {
            this.$forceUpdate(); // Force Vue to re-render to update the displayed time
          }, 100); // Update every 100 milliseconds for a smoother display
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    handleSubmit(data) {
      alert(`Submitted data: ${data}`);
      // Here you can add the logic to handle the submitted data
      console.log(data);
    },
    handleAlert(payload) {
      const { showAlert, itemId } = payload;
      // 处理接收到的数据
      this.showAlert = showAlert;
      this.disableScroll();
    },
    onEnterSearchImage() {
      let url = "https://cn.bing.com/visualsearch?mkt=zh-CN"
      window.open(url, '_blank');
    },
    onEnter() {
      let url = this.currentEnginx.selectEnginxSite + this.searchVal
      window.open(url, '_blank');
    },
    SwitchSearchEngine(enginx) {
      if (enginx.id === (this.searchEngine.length - 1)) {
        this.currentEnginx.selectEnginxId = 0;
        this.currentEnginx.selectEnginxSite = this.searchEngine[this.currentEnginx.selectEnginxId].site
      } else {
        this.currentEnginx.selectEnginxId += 1;
        this.currentEnginx.selectEnginxSite = this.searchEngine[this.currentEnginx.selectEnginxId].site
      }
    },
    handleUpdate(flag) {
      if (flag) {
        this.getTabDataList()
      }
    },
    onImageSwitch() {
      let randomNum = Math.floor(Math.random() * 37) + 1;
      // let newImageUrl = "http://nekoya.xyz:8085/image_" + randomNum + ".jpg"
      let newImageUrl = "https://data-1310058445.cos.ap-guangzhou.myqcloud.com/bg-image/image_" + randomNum + ".jpg"

      // 创建一个 Image 对象
      let img = new Image();

      // 设置图片的加载完成事件
      img.onload = () => {
        this.bgImage = newImageUrl;
      };

      // 设置图片的加载错误事件（可选）
      img.onerror = () => {
        alert("图片加载失败");
      };

      // 触发图片加载
      img.src = newImageUrl;
    }
  },
  computed: {
    getArrData(index) {
      return this.colors[ind]
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  }

}
</script>


<style scoped lang='css' scope>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';

.disNone {
  display: none;
}

.displayBlock {
  display: block !important;
}

.bodyContent {
  display: flex;
  background: var(--index_bg1);
  background-size: cover;
  background-attachment: fixed;
}

/* main */
.main-con {
  width: 1298.0006px;
  background: rgb(255 241 241 / 52%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 63.9994px 50.0006px 0 50.0006px;
  backdrop-filter: blur(7.0003px);
  /* Apply blur effect */
}

.search-con {
  width: 1197.9994px;
  height: 68.0006px;
  padding: 10.9997px 0;
  margin-bottom: 34.9997px;
  display: flex;
  justify-content: center;
}

.search-con .search {
  width: 737.6006px;
  height: 57.5194px;
  border-radius: 50.0006px;
  box-shadow: 0px 2.0006px 3.9994px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 34.9997px;
  display: flex;
  justify-content: left;
  background: #fff;
  gap: 20.0006px;
}

.search .search-main {
  width: 660.8006px;
  height: 57.5194px;
  border-radius: 50.0006px;
  box-shadow: 0px 2.0006px 3.9994px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 34.9997px;
  padding: 0 21.9994px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  backdrop-filter: blur(9.9994px);
  /* Apply blur effect */
  transition: transform .7s;
  transition: box-shadow .7s;
  background: #fff;
}


.search-con .search:hover {
  transform: translateY(-0.5837px);
  box-shadow: 0px 3.9994px 9.9994px 0px rgba(59, 20, 20, 0.6);
}

.search-main .search-logo {
  width: 31.0003px;
  height: 31.0003px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  margin-right: 16.9997px;
  user-select: none !important;
  /* overflow: hidden; */
  box-shadow: 0 0 0 4.1914px rgba(255, 255, 255, 0.95);
}

.search-main .search-logo img {
  width: 100%;
  height: 100%;
}

.search-main .searchInput {
  width: 435.9994px;
  height: 100%;
  background: none !important;
  outline: none;
  border: none;
  font-size: 16.921px;
  /* font-size: calc(0.625 * var(--font-size-base)); */
  font-family: 'LatoR';
  letter-spacing: .4128px;
  flex-grow: 1;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: calc(0.625 * var(--font-size-base));
  font-family: 'LatoR';
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: calc(0.625 * var(--font-size-base));
  font-family: 'LatoR';
}

input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: calc(0.625 * var(--font-size-base));
  font-family: 'LatoR';
}

.search-main .imgCon {
  width: 31.0003px;
  height: 31.0003px;
  border-radius: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  transition: box-shadow .3s;
  user-select: none !important;
}


.imgCon img {
  width: 100%;
  cursor: pointer;
}

.search-active {
  background: #8b403b !important;
}

.searchInput-active {
  color: #FFFFFF;
}


.search .search-image-switch {
  width: 31.0003px;
  height: 100%;
  border-radius: 100%;
  display: flex;
  justify-content: space-around;
  justify-items: center;
  transition: box-shadow .3s;
  user-select: none !important;
  align-items: center;
}

.search-image-switch .imgCon {
  width: 31.0003px;
  height: 31.0003px;
}


.main-con .con {
  width: 1195.0003px;
  background: linear-gradient(to bottom, rgba(252, 252, 252, 1) 0%, rgba(252, 252, 252, 0.67) 93%, rgba(183, 19, 19, 0) 100%);
  /* Use rgba for transparent background */
  border-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  backdrop-filter: blur(9.9994px);
  /* Apply blur effect */
}


.con .conItem {
  width: 1195.0003px;
  min-height: 54px;
  /* background: #f8f8f8; */
  /* background: red; */
  margin-bottom: 62.0006px;
  border-radius: 3.9206px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.conItem {
  padding: 26.0006px 36px;
}

.conItem span {
  color: #3d3d3d;
  font-weight: bold;
  font-size: calc(0.625 * var(--font-size-base));
  font-family: 'LatoR';
  margin-bottom: 3.9206px;
}

.alertBox {
  position: fixed;
  top: 0;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  background-color: white;
  padding: 20.0006px;
  box-shadow: 0 2.0006px 9.9994px rgba(0, 0, 0, 0.1);
  border-radius: 10.7597px;
  z-index: 1000;
  width: 450px;
  height: 249.9994px;
}
</style>