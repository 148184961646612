<template>
  <div class="main-con">
    <div class="right">
      <div class="item" v-for="(item, index) in rightItems" :key="index" :class="{
        rightItemActive: select === item.index,
        isBackGroundBlack: item.isBlack
      }" @click="active(item.index, item.path)">
        <img :src="item.src" :alt="item.alt">
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>

import rightItemsChatGPT from "../assets/images/svg/right-item-chagpt.svg"
import rightItemsStable_Diffusion from "../assets/images/svg/right-item-Stable_Diffusion.svg"
import rightItemsTYQW from "../assets/images/svg/right-item-TQYW.svg"

import axios from 'axios'; // 确保已正确导入 Axios

export default {
  name: 'AiHelperContentView',
  data() {
    return {
      rightItems: [
        {
          "src": rightItemsChatGPT,
          "alt": "ChatGPT",
          "index": 0,
          "isBlack": true,
          "path": "/aiHelper/ChatGPT"
        },
        {
          "src": rightItemsTYQW,
          "alt": "通义千问",
          "index": 1,
          "isBlack": false,
          "path": "/aiHelper/TYQW"
        },
        {
          "src": rightItemsStable_Diffusion,
          "alt": "Stable_Diffusion",
          "index": 2,
          "isBlack": false,
          "path": "/aiHelper/StableDiffusion"
        },
      ],
      select: 0
    };
  },
  watch: {},
  mounted() {
  },
  computed: {},
  methods: {
    active(index, path) {
      this.select = index
      // 使用$router.push来进行路由跳转
      this.$router.push(path);
      // 阻止a标签默认的href跳转行为
      return false;
    },
  }
};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';




/* 滚动条 */
/* 修改滚动条的宽度 */
::-webkit-scrollbar {
  width: 6.24px;
}

/* 修改滚动条的背景色 */
::-webkit-scrollbar-track {
  background: #212121;
}

/* 修改滚动条滑块的颜色 */
::-webkit-scrollbar-thumb {
  background: #383838;
}

/* 修改滚动条滑块的颜色（鼠标悬停时） */
::-webkit-scrollbar-thumb:hover {
  background: #383838;
}


.main-con {
  width: 100%;
  height: calc(100vh - 56.0006px);
  background: #212121;
  backdrop-filter: blur(7.0003px);
  font-family: 'LatoR';
  display: flex;
  flex-direction: row;
}

.main-con .right {
  background: #fff;
  width: 76.8px;
  display: flex;
  flex-direction: column;
  padding: 38.0006px 11.161px 0 11.161px;
  gap: 15.001px;
}

.right .item {
  width: calc(76.8px - 11.161px - 11.161px);
  height: calc(76.8px - 11.161px - 11.161px);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  border: 4.9997px solid #fff;
  overflow: hidden !important;
  transition: box-shadow .3s ease-in-out;
}


.rightItemActive {
  box-shadow: 0px 2.0006px 3.001px 0px rgba(0, 0, 0, 0.3) !important;
}

.right .item img {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.right .item:hover {
  box-shadow: 0px 2.0006px 3.001px 0px rgba(0, 0, 0, 0.3) !important;
}
</style>