import "lib-flexible";
import "normalize.css";
import { createApp } from "vue";

//引入element-plus
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

//引入图标
import '@fortawesome/fontawesome-free/css/all.css';

import App from "./App.vue";
import router from "./router";
import store from "./store";

import websocket from './websocket/websocket'; // 引入websocket封装

// 右键菜单
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

// iconfont
import './assets/iconfont/iconfont.js';
import './assets/css/icon.css'

const app = createApp(App);

app.use(ContextMenu)
app.use(store);
app.use(router);
app.use(websocket);
app.use(ElementPlus, {
  locale: zhCn
});

app.mount("#app");
