import store from '../store/index.js'

import { createRouter, createWebHistory } from "vue-router";
import EmailContentView from "../views/EmailContentView.vue";
import GameXJYContentView from "../views/GameXJYContentView.vue";
import NoteContentView from "../views/NoteContentView.vue";

import DataManagerView from "../views/DataManagerView.vue";
import DataCommonContentView from "../views/DataManagerView/DataCommonContentView.vue";
import DataAiContentView from "../views/DataManagerView/DataAiContentView.vue";
import DataXjyContentView from "../views/DataManagerView/DataXjyContentView.vue";

import AiHelperContentView from "../views/AiHelperContentView.vue";
import ChatGPTContentView from "../views/AiHelperViews/ChatGPTContentView.vue";
import TYQWContentView from "../views/AiHelperViews/TYQWContentView.vue";
import StableDiffusionContentView from "../views/AiHelperViews/StableDiffusionContentView.vue";

import WebSocketView from "../views/CommonViews/WebSocketView.vue";
import LoginView from "../views/AuthView/LoginView.vue";
import HomeContentView from "../views/HomeContentView.vue";
const routes = [
  {
    path: "/",
    name: "quick",
    component: HomeContentView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView
  },
  {
    path: "/email",
    name: "email",
    component: EmailContentView
  },
  {
    path: "/xjy",
    name: "xjy",
    component: GameXJYContentView
  },
  {
    path: "/aiHelper",
    name: "aiHelper",
    component: AiHelperContentView,
    children: [
      {
        path: "home",
        redirect: "/aiHelper/ChatGPT"
      },
      {
        path: "ChatGPT",
        name: "ChatGPT",
        component: ChatGPTContentView
      },
      {
        path: "TYQW",
        name: "TYQW",
        component: TYQWContentView
      },
      {
        path: "StableDiffusion",
        name: "StableDiffusion",
        component: StableDiffusionContentView
      }
    ]
  },
  {
    path: "/note",
    name: "note",
    component: NoteContentView
  },
  {
    path: "/data",
    name: "data",
    component: DataManagerView,
    children: [
      {
        path: "home",
        redirect: "/data/common"
      },
      {
        path: "common",
        name: "common",
        component: DataCommonContentView
      },
      {
        path: "ai",
        name: "ai",
        component: DataAiContentView
      },
      {
        path: "xjyData",
        name: "xjyData",
        component: DataXjyContentView
      },
    ]
  },
  {
    path: "/websocket",
    name: "websocket",
    component: WebSocketView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 是否需要强制修改密码
  const isNeedPassword = store.state.userInfo.isNeedPassword;
  if (isNeedPassword === 0) {
    store.commit('setDrawer', true); // 假设你有一个 mutation 来控制抽屉的显示
  }
  next(); // 跳转
})

export default router;
