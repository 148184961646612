// store.js
import { createStore } from "vuex";

//vuex数据本地化
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    select: 1,
    contextMenu: {
      contextMenuVisible: false
    },
    tableDataList:[],
    isDataLoaded: false,
    dataSelect: 0,
    dataParentSelect: 0,
    tableDataList: [{}],
    rightItemsContentSelect: "common",
    aiSetting: {
      chatgpt: {
        model: "",
        api: ""
      },
      tyqw: {
        model: "",
        api: ""
      },
      MidjourneyV6: {
        api: "",
        size: "",
        model: "",
        num: ""
      }
    },
    userInfo: {
      userId: 0,
      account: "",
      userName: "",
      ipAddr: "",
      ipVesion: "",
      local: "",
      token: "",
      email: "",
      phone: "",
      avatarUrl: "",
      login_method: 0,
      status: 0,
      lastLoginTime: null,
      createdAt: "",
      isNeedPassword: -1
    },
    drawer: false
  },
  mutations: {
    setSelect(state, value) {
      state.select = value;
    },
    setContextMenuVisible(state, value) {
      state.contextMenu.contextMenuVisible = value;
    },
    setDataSelect(state, value) {
      state.dataSelect = value;
    },
    setDataParentSelect(state, value) {
      state.dataParentSelect = value;
    },
    setTableDataList(state, value) {
      state.tableDataList = value;
    },
    setRightItemsContentSelect(state, value) {
      state.rightItemsContentSelect = value;
    },
    setAiSettingChatgpt(state, value) {
      state.aiSetting.chatgpt = value;
    },
    setAiSettingChatgptModel(state, value) {
      state.aiSetting.chatgpt.model = value;
    },
    setAiSettingChatgptApi(state, value) {
      state.aiSetting.chatgpt.api = value;
    },
    setAiSettingTyqw(state, value) {
      state.aiSetting.tyqw = value;
    },
    setAiSettingTyqwModel(state, value) {
      state.aiSetting.tyqw.model = value;
    },
    setAiSettingTyqwApi(state, value) {
      state.aiSetting.tyqw.api = value;
    },
    setAiSettingMidjourneyV6(state, value) {
      state.aiSetting.MidjourneyV6 = value;
    },
    setAiSettingMidjourneyV6Api(state, value) {
      state.aiSetting.MidjourneyV6.api = value;
    },
    setAiSettingMidjourneyV6Size(state, value) {
      state.aiSetting.MidjourneyV6.size = value;
    },
    setAiSettingMidjourneyV6Num(state, value) {
      state.aiSetting.MidjourneyV6.num = value;
    },
    setAiSettingMidjourneyV6Model(state, value) {
      state.aiSetting.MidjourneyV6.model = value;
    },
    setUser(state, value) {
      state.userInfo = value
    },
    setTableDataList(state, value) {
      state.tableDataList = value
    },
    setIsDataLoaded(state, value) {
      state.isDataLoaded = value
    },
    setDrawer(state, value) {
      state.drawer = value;
    }
  },
  plugins: [createPersistedState()] // 使用 Vuex Persistedstate 插件
});
