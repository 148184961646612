<template>
  <body>
    <div class="header-con">
      <div class="indexLogo" @click="navClick(1, '/', 1)">
        <img src="./assets/images/LOGO.svg" alt="">
      </div>
      <div class="nav">
        <div class="nav-item" v-for="item in navItems" :key="item.id" :class="{ 'active-navItem': isActive(item.id) }"
          @click="navClick(item.id, item.path, item.isFooter)">
          <a href="#">{{ item.title }}</a>
        </div>
      </div>
      <div class="options">
        <div class="options-item options-item-avatar" @mouseenter="toggleAvatarContent(true)"
          @mouseleave="toggleAvatarContent(false)">
          <div class="item-avatar-img"
            :class="{ 'animation-decline-scale-default': !isAvatarContent, 'animation-decline-scale-acvite': isAvatarContent }"
            @click="isEditAvatar ? '' : !$store.state.userInfo.userId ? navClick(-1, '/login', 0) : navClick(4, '/data/common', 0)">
            <img
              :src="$store.state.userInfo.avatarUrl || 'https://data-1310058445.cos.ap-guangzhou.myqcloud.com/svg/defaultAvatar.svg'"
              alt="">
          </div>
          <div class="item-avatar-content-bg" v-show="showAvatarContent"
            :class="{ 'opacity-100': isAvatarContent, 'opacity-0': !isAvatarContent }"
            @transitionend="handleTransitionEnd()">
            <div class="item-avatar-content">
              <div class="avatar-content-image avatar-content-image-default"
                :class="{ 'animation-decline-scale-content-default': isAvatarContent, 'opacity-0': !isAvatarContent }">
                <img @click="isEditAvatar = !isEditAvatar"
                  :src="$store.state.userInfo.avatarUrl || 'https://data-1310058445.cos.ap-guangzhou.myqcloud.com/svg/defaultAvatar.svg'"
                  alt="">
              </div>

              <div class="avatar-content-default" v-if="!isEditAvatar"
                :class="{ 'opacity-100': !isEditAvatar, 'opacity-0': isEditAvatar }">
                <div class="avatar-info">
                  <div class="info-item info-account">{{ $store.state.userInfo.account }}</div>
                  <div class="info-item info-uuid">
                    ID: {{ $store.state.userInfo.userId }}
                    <svg class="icon" aria-hidden="true" @click="copyTextFunction($store.state.userInfo.userId)">
                      <use xlink:href="#icon-fuzhi"></use>
                    </svg>
                  </div>
                </div>
                <div class="avatar-options">
                  <div class="options-option">
                    <div class="option-icon-pre">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-putongyonghu"></use>
                      </svg>
                    </div>
                    <div class="option-text">
                      个人中心
                    </div>
                    <div class="option-icon-back">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shouqi"></use>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="avatar-others">
                  <div class="options-option" @click="logout()">
                    <div class="option-icon-pre">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-tuichudenglu"></use>
                      </svg>
                    </div>
                    <div class="option-text">
                      退出登录
                    </div>
                    <div class="option-icon-back">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shouqi"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div class="avatar-content-image avatar-content-image-edit" v-if="isEditAvatar"
                :class="{ 'opacity-100': isEditAvatar, 'opacity-0': !isEditAvatar }">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-bianji1"></use>
                </svg>
              </div>
              <div class="avatar-content-editAvatar" v-if="isEditAvatar"
                :class="{ 'opacity-100': isEditAvatar, 'opacity-0': !isEditAvatar }">
                <div class="editAvatar-avatars-grid">
                  <div class="avatar-item" v-for="avatar in avatars" :key="avatar.id" @click="isSelectAvatar = avatar.id"
                    :style="{ 'box-shadow': isSelectAvatar === avatar.id ? '0px 2px 4px 0px rgba(0, 0, 0, 0.4)' : '0px 1px 1px 0px rgba(0, 0, 0, 0.3)' }">
                    <img :src="avatar.url" alt="">
                  </div>
                  <div class="avatar-item" @click="initAvatars()">
                    <svg t="1727948182930" class="icon" viewBox="0 0 1025 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="2119" width="200" height="200">
                      <path
                        d="M381.396114 1024c-8.777143 0-14.628571-2.925714-20.48-8.777143l-351.085714-380.342857c-8.777143-8.777143-11.702857-17.554286-8.777143-26.331429l117.028572-468.114285c2.925714-11.702857 11.702857-20.48 23.405714-23.405715l497.371428-117.028571c8.777143-2.925714 20.48 0 26.331429 8.777143l351.085714 351.085714c5.851429 5.851429 8.777143 17.554286 8.777143 26.331429l-117.028571 468.114285c-2.925714 8.777143-8.777143 17.554286-20.48 20.48l-497.371429 146.285715c-2.925714 2.925714-5.851429 2.925714-8.777143 2.925714zM62.493257 605.622857l327.68 354.011429 465.188572-137.508572 108.251428-435.931428L635.933257 61.44 170.744686 169.691429 62.493257 605.622857z"
                        fill="#ffffff" p-id="2120"></path>
                      <path
                        d="M644.7104 248.685714c0-23.405714-20.48-43.885714-43.885714-43.885714S556.938971 225.28 556.938971 248.685714s20.48 43.885714 43.885715 43.885715S644.7104 272.091429 644.7104 248.685714zM630.081829 702.171429c-23.405714 0-43.885714 20.48-43.885715 43.885714s20.48 43.885714 43.885715 43.885714 43.885714-20.48 43.885714-43.885714-20.48-43.885714-43.885714-43.885714z m146.285714-146.285715c-23.405714 0-43.885714 20.48-43.885714 43.885715s20.48 43.885714 43.885714 43.885714 43.885714-20.48 43.885714-43.885714-20.48-43.885714-43.885714-43.885715z m-468.114286 117.028572c-23.405714 0-43.885714 20.48-43.885714 43.885714s20.48 43.885714 43.885714 43.885714 43.885714-20.48 43.885714-43.885714-20.48-43.885714-43.885714-43.885714z m-14.628571-102.4c0-23.405714-20.48-43.885714-43.885715-43.885715S205.853257 547.108571 205.853257 570.514286 226.333257 614.4 249.738971 614.4s43.885714-20.48 43.885715-43.885714z m-29.257143-146.285715c0-23.405714-20.48-43.885714-43.885714-43.885714S176.596114 400.822857 176.596114 424.228571 197.076114 468.114286 220.481829 468.114286 264.367543 447.634286 264.367543 424.228571zM422.356114 877.714286h-8.777143c-14.628571-2.925714-23.405714-20.48-20.48-35.108572L489.647543 497.371429 243.887543 263.314286c-11.702857-11.702857-11.702857-29.257143 0-40.96s29.257143-11.702857 40.96 0l257.462857 248.685714c8.777143 8.777143 11.702857 17.554286 8.777143 29.257143l-99.474286 362.788571c-5.851429 5.851429-17.554286 14.628571-29.257143 14.628572z"
                        fill="#ffffff" p-id="2121"></path>
                      <path
                        d="M521.8304 520.777143c-14.628571 0-29.257143-11.702857-32.182857-26.331429-2.925714-17.554286 8.777143-35.108571 26.331428-38.034285L849.5104 383.268571c17.554286-2.925714 35.108571 8.777143 38.034286 26.331429s-8.777143 35.108571-26.331429 38.034286L527.681829 520.777143h-5.851429z"
                        fill="#ffffff" p-id="2122"></path>
                    </svg>
                  </div>
                </div>
                <div class="editAvatar-button">
                  <button class="button-cancel" @click="cancelAvatarEdit()">取消</button>
                  <button class="button-edit" @click="toEditAvatar()">修改</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //QuickContentView -->
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <!-- //FooterView -->
    <FooterView v-show="isFooterShow" />

    <InitModifyPassword />
  </body>
</template>

<script>
import FooterView from './views/FooterView.vue'
import QuickContentView from './views/QuickContentView.vue'
import InitModifyPassword from './components/InitModifyPassword.vue'

import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'

import { notificationTip, notificationTipTime } from './utils/messageTip.js'
import request from './http/request'

import { copyText } from './utils/common.js'

export default {
  name: 'App',
  components: {
    FooterView,
    QuickContentView,
    InitModifyPassword
  },
  data() {
    return {
      isFooterShow: true,
      navItems: [],
      isAvatarContent: false,
      showAvatarContent: true,
      isEditAvatar: false, // 是否需要修改头像
      avatars: [],
      allAvatars: [
      ],
      isSelectAvatar: -1
    }
  },
  mounted() {
    //数据初始化
    request.get("/api/nav/list", {})
      .then(response => {
        console.log(response.data);
        this.navItems = response.data;

        // 跳转到首页,如果没有登录会自动跳转到/login
        this.$store.commit('setSelect', 1);
        this.$router.push("/")
      })
      .catch(error => {
        console.error('Error:', error);
      });
  },
  watch: {
    isAvatarContent(newVal, oldVal) {
      if (newVal && !oldVal) {

      }
    },
    isEditAvatar(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.initAvatars();
      }
    }
  },
  methods: {
    initAvatars() {
      this.avatars = []
      let count = 36;
      let ids = []
      for (let i = 1; i <= 8; i++) {
        let randomNum = Math.floor(Math.random() * count) + 1;
        while (ids.includes(randomNum)) {
          randomNum = Math.floor(Math.random() * count) + 1;
        }
        ids.push(randomNum)
        this.avatars.push({
          "id": randomNum,
          "url": `https://data-1310058445.cos.ap-guangzhou.myqcloud.com/avatar-image/avatar-${randomNum}.jpg`,
        })
      }
    },
    async toEditAvatar() {
      // 发起修改头像请求
      let user = this.$store.state.userInfo
      user.avatarUrl = `https://data-1310058445.cos.ap-guangzhou.myqcloud.com/avatar-image/avatar-${this.isSelectAvatar}.jpg`
      let res = await request.patch("/api/user/update", user)

      if (res && res.code === 200) {
        // 更新数据到vuex
        this.$store.commit('setSelect', res.data);
        this.isEditAvatar = false
        this.isSelectAvatar = -1
        notificationTipTime("修改成功", "头像修改成功", "success", 1500)
      } else {
        this.isEditAvatar = false
        this.isSelectAvatar = -1
        notificationTipTime("修改头像", "头像修改失败", "error", 2000)
      }

    },
    cancelAvatarEdit() {
      this.isEditAvatar = false
      this.isSelectAvatar = -1
    },
    changeSelect(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setSelect', data);
    },
    isActive(itemId) {
      return this.$store.state.select === itemId;
    },
    navigateTo(path) {
      // 使用$router.push来进行路由跳转
      this.$router.push(path);
      // 阻止a标签默认的href跳转行为
      return false;
    },
    navClick(index, path, isFooterShow) {
      this.changeSelect(index)
      this.navigateTo(path)
      this.isFooterShow = isFooterShow
    },
    handleTransitionEnd() {
      if (!this.isAvatarContent) {
        this.showAvatarContent = false; // 动画结束后，设置 v-show 为 false
      }
    },
    toggleAvatarContent(isEntering) {
      if (this.$store.state.userInfo.userId) {
        if (isEntering) {
          // 鼠标进入时
          this.isAvatarContent = true;
          this.showAvatarContent = true; // 显示内容
        } else {
          // 鼠标离开时
          this.isAvatarContent = false;
          this.isEditAvatar = false
          this.isSelectAvatar = -1
          setTimeout(() => {
            // 如果在一定时间内没有再次进入，隐藏内容
            if (!this.isAvatarContent) {
              this.showAvatarContent = false;
            }
          }, 200); // 设置适当的延迟
        }
      }
    }
    ,
    async logout() {
      // 发起注销请求
      let res = await request.post("/api/auth/logout", this.$store.state.userInfo);
      console.log("res", res);
      if (res && res.code === 200) {
        // 将vuex中数据清除          
        this.$store.commit('setUser', {});
        // 初始化值
        this.isAvatarContent = false,
          this.showAvatarContent = true
        // 跳转到登录页
        this.$router.push("/login");

        this.$forceUpdate(); // 强制更新组件，清空数据
        notificationTip("注销", "注销成功!", "success")
      } else {
        notificationTip("注销", "注销失败!", "error")
      }

    },
    copyTextFunction(text) {
      copyText(text)
    }
  },
  setup() {
    // console.log('1-开始创建组件-setup')
    const data = reactive({})
    onBeforeMount(() => {
      // console.log('2.组件挂载页面之前执行----onBeforeMount')
    })
    onMounted(() => {
    })
    return {
      ...toRefs(data),
    }
  },
  computed: {
    request
  }
}
</script>


<style scoped>
@import './assets/css/common.css';
@import './assets/css/variable.css';
@import url("./assets/css/icon.css");

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

@keyframes declineScaleDefault {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}


@keyframes declineScaleContentDefault {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  35% {
    opacity: .9;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes declineScaleAcvite {
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(24px) scale(1.6);
    opacity: 1;
  }

  100% {
    transform: translateY(24px) scale(1.6);
    opacity: 0;
  }
}

.animation-decline-scale-default {
  animation-name: declineScaleDefault;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animation-decline-scale-content-default {
  animation-name: declineScaleContentDefault;
  animation-duration: 2.6s;
  animation-fill-mode: forwards;
}

.animation-decline-scale-acvite {
  animation-name: declineScaleAcvite;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.isDisplayBlock {
  display: block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* 固定背景图片 */
/* body {
  background: var(--index_bg1);
  background-size: cover;
  background-attachment: fixed;
} */


/* header */
.header-con {
  position: sticky;
  z-index: 999;
  top: 0;
  width: 1920px;
  height: var(--header-height);
  background: #ffffff;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  box-shadow: 0px 3.9994px 9.9994px 0px rgba(0, 0, 0, 0.3);
  padding: 0 72px;
  gap: 210px;
}

.header-con .indexLogo {
  width: 145.0003px;
  height: 38.0006px;
  cursor: pointer;
  /* background: #000; */
  margin: 9.001px 0;
  text-align: center;
}

.indexLogo img {
  height: 100%;
  border-bottom: 3.001px none #94525e;
}


.header-con .nav {
  width: auto;
  height: 100%;
  /* background: green; */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}


.nav .nav-item {
  width: 138px;
  height: 56.0006px;
  /* background: pink; */
  text-align: center;
  cursor: pointer;
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-bottom: 4.4237px solid #67514e00;
  transition: all .4s ease-in-out;
  box-sizing: border-box;
}

.nav .nav-item:hover {
  border-bottom: 6.9197px solid #67514e2f;
  background: #67514e04;
}

.nav .nav-item:hover a {
  color: #67514e;
}


.nav-item a {
  width: auto;
  line-height: 56.0006px;
  font-size: 14.0794px;
  /* font-size: calc(0.625 * var(--font-size-base)); */
  font-weight: normal;
  letter-spacing: 0em;

  /* 导航栏文字 */
  color: #868686;
  transition: color .2s ease-in-out;
}

.active-navItem {
  border-bottom: 4.4237px solid #67514E !important;
}

.active-navItem a {
  font-weight: bold;
}

/* options */

.options {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.options .options-item {
  width: 46px;
  height: 46px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 36px;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow .4s ease-in-out;
}

.options-item:hover {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
}

.options-item .options-item-avatar {
  position: relative;
  box-sizing: content-box;
  cursor: pointer;
  overflow: visible;
  /* 确保内容不被裁剪 */
}



.options-item-avatar .item-avatar-img {
  position: absolute;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  transition: all .4s ease-in-out;
  /* 使用transition */
}




.item-avatar-img img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.options-item-avatar .item-avatar-content-bg {
  position: absolute;
  top: 53px;
  width: 300px;
  border-radius: 20px;
  transition: opacity .5s ease-in-out;
}

.item-avatar-content-bg .item-avatar-content {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px 20px 20px;
  gap: 12px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid #E3E5E7;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  transition: opacity 1s ease-in-out;
}

.item-avatar-content .avatar-content-image {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%) translateY(-50%);
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0px calc(1px * 1.6) calc(1px * 1.6) 1px rgba(0, 0, 0, 0.3);
  z-index: 3;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.item-avatar-content .avatar-content-image-default {}

.item-avatar-content .avatar-content-image-edit {
  background: rgba(0, 0, 0, .669);
}

.avatar-content-image-edit svg {
  font-size: 30px;
  color: rgb(230, 230, 230);
}

.avatar-content-image img {
  width: 100%;
}

.item-avatar-content-bg .avatar-content-default {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 20px;
  transition: opacity 1s ease-in-out;
}

.avatar-content-default .avatar-info {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0 4px 0;
  align-self: stretch;
  font-family: Alibaba Sans;
  gap: 4px;
}

.avatar-info .info-item {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
}

.avatar-info .info-account {
  font-size: 20px;
  font-weight: bold;
  color: #696969;
}

.avatar-info .info-uuid {
  flex-direction: row;
  gap: 4px;
  font-size: 14px;
  color: #999;
}

.avatar-content-default .avatar-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.avatar-options .options-option {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  gap: 16px;
  transition: background .5s ease-in;
}

.options-option .option-icon-pre {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #61666D;
  transition: color .5s ease-in;
}

.options-option .option-icon-back {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  color: #61666D;
  transition: color .5s ease-in;
}

.options-option .option-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  font-size: 17px;
  font-family: 'Alibaba Sans';
  font-weight: 400;
  color: #61666D;
  transition: color .5s ease-in;
}

.avatar-options .options-option:hover {
  background: #65bf47af;
}

.avatar-options .options-option:hover .option-icon-pre {
  color: #fff;
}

.avatar-options .options-option:hover .option-icon-back {
  color: #fff;
}

.avatar-options .options-option:hover .option-text {
  color: #fff;
}


.avatar-content-default .avatar-others {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 8px;
  border-top: 1px solid #E3E5E7;
}

.avatar-others .options-option {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 16px;
  transition: background .5s ease-in;
}



.avatar-others .options-option .option-icon-pre {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #61666D;
  transition: color .5s ease-in;
}

.avatar-others .options-option .option-icon-back {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
  color: #61666D;
  transition: color .5s ease-in;
}

.avatar-others .options-option .option-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  font-size: 17px;
  font-family: 'Alibaba Sans';
  font-weight: 400;
  color: #61666D;
  transition: color .5s ease-in;
}

.avatar-others .options-option:hover {
  background: #65bf47af;
}

.avatar-others .options-option:hover .option-icon-pre {
  color: #fff;
}

.avatar-others .options-option:hover .option-icon-back {
  color: #fff;
}

.avatar-others .options-option:hover .option-text {
  color: #fff;
}



/* .item-avatar-content-bg */
.item-avatar-content-bg .avatar-content-editAvatar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
  transition: opacity 1s ease-in-out;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #E3E5E7;
}

.avatar-content-editAvatar .editAvatar-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.editAvatar-button button {
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  font-family: 'Alibaba Sans';
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.editAvatar-button .button-cancel {
  border: 1px solid rgb(245, 245, 245);
  color: #61666D;
}

.editAvatar-button .button-edit {
  background: #4fac2f;
  color: #fff;
}

.editAvatar-button .button-edit:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
}


.avatar-content-editAvatar .editAvatar-avatars-grid {
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: repeat(3, 80px);
}

.editAvatar-avatars-grid .avatar-item {
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.editAvatar-avatars-grid .avatar-item:nth-last-of-type(1) {
  background: #000;
}

.avatar-item img {
  width: 100%;
  border-radius: 100%;
}

@keyframes svgLoad {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.avatar-item svg {
  font-size: 45px;
  color: #fff;
  transform: rotate(320deg);
  animation: svgLoad 2s infinite;
  animation-duration: alternate-reverse;
  animation-timing-function: ease;
}
</style>
