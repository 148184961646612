<template>
    <div class="content-bg">
        <div class="modelState">
            {{ this.$store.state.aiSetting.tyqw.model }}
        </div>
        <div class="content" :class="{ 'element-init-bg': isInit }">
            <div class="elements scrollable-container" ref="scrollContainer" v-loading="loading"
                element-loading-text="加载中...">
                <div class="element-init" v-if="isInit">
                    <div class="init-top">
                        <div class="init-top-logo">
                            <img src="../../assets/images/svg/right-item-TQYW.svg" alt="通义千问">
                        </div>
                        <div class="init-top-title">
                            你好，我是通义
                        </div>
                        <div class="init-top-text">
                            通情，达义。你的全能AI助手
                        </div>
                    </div>
                    <div class="init-bottom">
                        <div class="init-bottom-btn">
                            <!-- <div class="init-bottom-btn-hyp">
                            <div class="hyp-ico">
                                <img src="../../assets/images/svg/tools-item-regenerate.svg" alt="">
                            </div>
                            <div class="hyp-text">
                                换一批
                            </div>
                        </div> -->
                        </div>
                        <div class="init-bottom-content">
                            <div class="bottom-content-left">
                                <div class="bottom-content-left-title">
                                    <div class="bottom-content-left-title-ico">
                                        <img src="../../assets/images/svg/title-ico-aiSearch.svg" alt="">
                                    </div>
                                    <div class="bottom-content-left-title-content">
                                        AI搜索
                                    </div>
                                </div>
                                <div class="bottom-content-left-content">
                                    <div class="bottom-content-left-content-item" v-for="item in randomInitBottomLeftData"
                                        @click="initSubmit(item.contentData)">
                                        <div class="point"></div>
                                        <div class="bottom-content-left-content-item-text">
                                            {{ item.contentData }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-content-right">
                                <div class="bottom-content-right-item" v-for="item in randomInitBottomRightData"
                                    @click="initSubmit(item.contentData)">
                                    <div class="bottom-content-right-title">
                                        <div class="bottom-content-right-title-ico">
                                            <img :src="item.iconUrl" :alt="item.iconType">
                                        </div>
                                        <div class="bottom-content-right-title-content">
                                            {{ item.title }}
                                        </div>

                                    </div>
                                    <div class="bottom-content-right-text">
                                        {{ item.contentData }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="element-item" v-if="!isInit" v-for="item in request_response" :key="item.id">
                    <div class="item-request">
                        <div class="item-content user-select">
                            <AnimatedText :text="item.request" direction="ltr" />
                        </div>
                    </div>
                    <div class="item-response" v-show="item.isResponse">
                        <div class="item-response-logo">
                            <img src="../../assets/images/svg/right-item-TQYW.svg" alt="">
                        </div>
                        <div class="item-response-content">
                            <div class="item-response-content-text user-select">
                                <AnimatedText :text="item.response" direction="ltr" />
                            </div>
                            <div class="item-response-content-tools">
                                <div class="tools-bg">
                                    <div class="tools-item">
                                        <img :src="toolsCopySelect" alt="复制" @click="copyFun(item.response)">
                                    </div>
                                    <div class="tools-item">
                                        <img src="../../assets/images/svg/tools-item-regenerate.svg" alt="重新生成"
                                            @click="regenerateFun(item.request)">
                                    </div>
                                    <div class="tools-item">
                                        <img src="../../assets/images/svg/tools-item-ReplacementModel.svg" alt="更换模型">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="input" :class="{ 'input-border': isFocused }">
                <div class="input-text">
                    <input type="text" @keydown.enter="onEnter" name="inputText" id="inputText" v-model="inputText"
                        placeholder="请输入信息给通义千问" @focus="isFocused = true" @blur="isFocused = false" autocomplete="off">
                </div>
                <div class="input-btn" :disabled="isSubmit === 0" @click="handleSubmit(inputText)">
                    <img v-for="(item, index) in submitSvgs" :key="index" v-show="item.index === isSubmit" :src="item.src"
                        alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import submit_v1_f from "../../assets/images/svg/submit_v1_f.svg"
import submit_v1_t from "../../assets/images/svg/submit_v1_t.svg"
import submit_v1_run from "../../assets/images/svg/submit_v1_run.svg"

import tools_item_copy_normal from "../../assets/images/svg/tools-item-copy-normal.svg"
import tools_item_copy_success from "../../assets/images/svg/tools-item-copy-success.svg"


import AnimatedText from '../../components/AnimatedText.vue'

import request_ from '@/http/request';
import { notificationTip, notificationTipTime } from '../../utils/messageTip.js'

export default {
    name: 'TYQWContentView',
    components: {
        AnimatedText,
    },
    data() {
        return {
            isFocused: false,
            submitSvgs: [
                {
                    "src": submit_v1_f,
                    "index": 0
                },
                {
                    "src": submit_v1_t,
                    "index": 1
                },
                {
                    "src": submit_v1_run,
                    "index": 2
                },
            ],
            isSubmit: 0,
            inputText: '',
            request_response: [
            ],
            scrollContainer: null,
            loading: false,
            toolsCopy: {
                "normal": tools_item_copy_normal,
                "success": tools_item_copy_success
            },
            toolsCopySelect: null,
            initBottomRightData: [
                {
                    "showData": "扩写文本、丰富内容：今天天气不错",
                    "sourceType": "qianwen",
                    "contentData": "扩写文本、丰富内容：今天天气不错",
                    "iconType": "icon_common",
                    "promptCode": "a6",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i2/O1CN01EJUdKD1wSCTCAd2Tz_!!6000000006306-2-tps-120-120.png",
                    "categoryCode": "chuangyi_wenan",
                    "title": "扩写助手",
                    "categoryName": "创意文案"
                },
                {
                    "showData": "你擅长写笑话且懂得程序员的梗：1. 写的笑话要有趣；2. 笑话的出发点和落脚点是计算机相关的某一个特点，程序员可以理解。基于“苹果”写这个笑话",
                    "sourceType": "qianwen",
                    "contentData": "你擅长写笑话且懂得程序员的梗：1. 写的笑话要有趣；2. 笑话的出发点和落脚点是计算机相关的某一个特点，程序员可以理解。基于“苹果”写这个笑话",
                    "iconType": "icon_common",
                    "promptCode": "chengxu",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i2/O1CN013M1tGS1yRP0tC3GlB_!!6000000006575-2-tps-120-120.png",
                    "categoryCode": "quwei_shenghuo",
                    "title": "程序员笑话",
                    "categoryName": "趣味生活"
                },
                {
                    "showData": "将工作概要整理成周报，依次罗列带有数据的进展、明确下阶段todo。输入：熟悉新环境，上线新功能",
                    "sourceType": "qianwen",
                    "contentData": "将工作概要整理成周报，依次罗列带有数据的进展、明确下阶段todo。输入：熟悉新环境，上线新功能",
                    "iconType": "icon_common",
                    "promptCode": "zhoubao",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i2/O1CN01qlRyJI1q94EIwVzVm_!!6000000005452-2-tps-120-120.png",
                    "categoryCode": "bangong_zhuli",
                    "title": "周报小助理",
                    "categoryName": "办公助理"
                },
                {
                    "showData": "你擅长解答计算机专业相关知识，请为我解答以下问题：如何获取API接口信息",
                    "sourceType": "qianwen",
                    "contentData": "你擅长解答计算机专业相关知识，请为我解答以下问题：如何获取API接口信息",
                    "iconType": "icon_common",
                    "promptCode": "a54",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i1/O1CN01Rjrvzr1xW9VLlxyzG_!!6000000006450-2-tps-120-120.png",
                    "categoryCode": "xuexi_zhushou",
                    "title": "计算机专业知识问答",
                    "categoryName": "学习助手"
                },
                {
                    "showData": "你擅长写短视频剧本：1.剧情紧凑；2.视觉冲击力强 现在你需要根据以下需求写短视频剧本：30天周游世界",
                    "sourceType": "qianwen",
                    "contentData": "你擅长写短视频剧本：1.剧情紧凑；2.视觉冲击力强 现在你需要根据以下需求写短视频剧本：30天周游世界",
                    "iconType": "icon_common",
                    "promptCode": "a3",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i2/O1CN01EJUdKD1wSCTCAd2Tz_!!6000000006306-2-tps-120-120.png",
                    "categoryCode": "chuangyi_wenan",
                    "title": "短视频剧本",
                    "categoryName": "创意文案"
                },
                {
                    "showData": "你是一个高情商的人，针对我给的问题，都可以给出高情商的回复。我的问题是：亲戚问我月薪多少",
                    "sourceType": "qianwen",
                    "contentData": "你是一个高情商的人，针对我给的问题，都可以给出高情商的回复。我的问题是：亲戚问我月薪多少",
                    "iconType": "icon_common",
                    "promptCode": "a2",
                    "iconUrl": "https://gw.alicdn.com/imgextra/i2/O1CN013M1tGS1yRP0tC3GlB_!!6000000006575-2-tps-120-120.png",
                    "categoryCode": "quwei_shenghuo",
                    "title": "高情商回复",
                    "categoryName": "趣味生活"
                }
            ],
            initBottomLeftData: [
                {
                    "id": 0,
                    "contentData": "通义千问2.5有哪些升级"
                },
                {
                    "id": 1,
                    "contentData": "嫦娥六号6月2日在月球背面着陆"
                },
                {
                    "id": 3,
                    "contentData": "张雪峰高考志愿填报服务被热抢"
                },
                {
                    "id": 4,
                    "contentData": "SpaceX星舰第四次试飞成功"
                },
                {
                    "id": 5,
                    "contentData": "歌手2024开播后有哪些热梗 "
                },
            ],
            items: [], //配置文件
        };
    },
    watch: {
        inputText() {
            // 动态监听 inputText 的变化并更新 isSubmit
            if (this.isSubmit !== 2) {
                this.isSubmit = this.inputText && this.inputText.trim() ? 1 : 0;
            }
        }
    },
    mounted() {
        this.scrollContainer = this.$refs.scrollContainer;
        this.scrollToBottom();
        // console.log(this.scrollContainer)
        // 在 mounted 生命周期钩子中初始化 toolsCopySelect
        this.toolsCopySelect = this.toolsCopy.normal;

        //初始化配置文件到全局
        this.getTableListDataByAxios()
    },
    computed: {
        isInit() {
            if (this.request_response.length === 0) {
                return true;
            }
            return false;
        },
        randomInitBottomRightData() {
            // 复制数组
            let tempArray = [...this.initBottomRightData];

            // 打乱数组顺序
            for (let i = tempArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
            }

            // 取前面三个元素
            return tempArray.slice(0, 3);
        },
        randomInitBottomLeftData() {
            // 复制数组
            let tempArray = [...this.initBottomLeftData];

            // 打乱数组顺序
            for (let i = tempArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
            }

            // 取前面三个元素
            return tempArray;
        }
    },
    methods: {
        async copyFun(response) {
            try {
                await navigator.clipboard.writeText(response); // 使用 Clipboard API 复制文本
                this.toolsCopySelect = this.toolsCopy.success
                setTimeout(() => {
                    this.toolsCopySelect = this.toolsCopy.normal
                }, 2000);
                //复制成功！
            } catch (err) {
                //复制失败！
            }

        },
        regenerateFun(request) {
            this.handleSubmit(request);
        },
        async handleSubmit(request) {
            if (request || this.isSubmit === 1) {
                if (request) {
                    this.inputText = request
                }
                // 模拟提交,返回 response
                if (this.inputText.trim()) { // 确保输入的文本不为空
                    let formData = new FormData();
                    formData.append("msg", this.inputText);

                    try {
                        // this.loading = true
                        this.isSubmit = 2
                        let id = Date.now();
                        const newItem = {
                            "id": id, // 使用当前时间戳作为唯一ID
                            "request": this.inputText,
                            "response": "",
                            "isResponse": false
                        };
                        this.request_response.push(newItem);
                        this.$nextTick(() => {
                            this.scrollToBottom(); // 在数据更新后调用 scrollToBottom 方法
                        });

                        // 使用包装的doPost函数发送请求
                        // const resp = await doPost('/api/tyqw', new URLSearchParams({ msg: this.inputText }));
                        let content = this.inputText;
                        let token = this.$store.state.aiSetting.tyqw.api;
                        let model = this.$store.state.aiSetting.tyqw.model;
                        let headers_ = {
                            'auth': '',
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                        var data = {
                            "model": `${model}`,
                            "messages": [
                                {
                                    "role": "user",
                                    "content": `${content}`
                                }
                            ]
                        };


                        let url = 'https://api.302.ai/v1/chat/completions'
                        const resp = await request_.post(url, data, { headers: headers_ });


                        this.isSubmit = 0

                        //根据id找到原本的数据，追加response
                        if (resp) {
                            const item = this.request_response.find(item => item.id === id);
                            if (item) {
                                // 修改response属性内容
                                item.response = resp.choices[0].message.content;
                                item.isResponse = true
                            } else {
                                notificationTipTime("通义千问", "网络连接失败!-1001 ", "error", 2000)
                            }

                            this.inputText = ''; // 清空输入框
                            this.$nextTick(() => {
                                this.scrollToBottom(); // 在数据更新后调用 scrollToBottom 方法
                            });
                        } else {
                            this.message = "请求失败";
                            notificationTipTime("通义千问", "网络连接失败!-1002 ", "error", 2000)
                        }
                    } catch (error) {
                        notificationTipTime("通义千问", "网络连接失败! " + error, "error", 2000)
                        this.message = 'Error occurred';
                    }
                }
            }
        },
        onEnter(event) {
            if (this.isSubmit === 1) {
                this.handleSubmit();
            } else {
                event.preventDefault();  // 禁用回车键的默认行为
            }
        },
        // 滚动到底部
        scrollToBottom() {
            if (this.scrollContainer) {
                this.$nextTick(() => {
                    this.scrollContainer.scrollTop = this.scrollContainer.scrollHeight;
                });
            }
        },
        initSubmit(initText) {
            this.inputText = initText;
            this.handleSubmit(initText)
        },
        async getTableListDataByAxios() {
            let res = await request_.get("/api/ai/setting/list")
            console.log("res", res);


            if (res && res.code === 200) {
                this.items = res.data;
                this.initializeSelectValues();
                notificationTipTime("获取AI助手配置", "配置获取成功!", "success", "1000")
            } else {
                notificationTipTime("获取AI助手配置", "配置获取失败!", "error", "1000")
            }

        },
        initializeSelectValues() {
            this.items.forEach(item => {
                if (item.configurationTitle === "ChatGPT设置") {
                    item.itemContent.forEach(itemC => {
                        if (itemC.options.length > 0) {
                            const selectedOption = itemC.options.find(option => option.isSelect);
                            if (selectedOption) {
                                this.changeAiSettingChatgptModel(selectedOption.optionValue);
                            }
                        } else {
                            this.changeAiSettingChatgptApi(itemC.contentInputValue);
                        }
                    });
                } else if (item.configurationTitle === "通义千问设置") {
                    item.itemContent.forEach(itemC => {
                        if (itemC.options.length > 0) {
                            const selectedOption = itemC.options.find(option => option.isSelect);
                            if (selectedOption) {
                                this.changeAiSettingTyqwModel(selectedOption.optionValue);
                            }
                        } else {
                            this.changeAiSettingTyqwApi(itemC.contentInputValue);
                        }
                    });
                } else if (item.configurationTitle === "图片生成设置") {
                    console.log("xxx", item.itemContent);
                    item.itemContent.forEach(itemC => {
                        if (itemC.contentText === "模型(model)") {
                            const selectedOption = itemC.options.find(option => option.isSelect);
                            // console.log("model = ", selectedOption, itemC.contentText);
                            if (selectedOption) {
                                this.changeAiSettingMidjourneyV6Model(selectedOption.optionValue);
                            }
                        }

                        if (itemC.contentText === "尺寸(size)") {
                            const selectedOption = itemC.options.find(option => option.isSelect);
                            // console.log("size = ", selectedOption);
                            if (selectedOption) {
                                this.changeAiSettingMidjourneyV6Size(selectedOption.optionValue);
                            }
                        }
                    });
                    this.changeAiSettingMidjourneyV6Api(item.itemContent[0].contentInputValue);
                    this.changeAiSettingMidjourneyV6Num(item.itemContent[1].contentInputValue);

                    // console.log(this.$store.state.aiSetting.MidjourneyV6.api);
                    // console.log(this.$store.state.aiSetting.MidjourneyV6.size);
                    // console.log(this.$store.state.aiSetting.MidjourneyV6.model);
                    // console.log(this.$store.state.aiSetting.MidjourneyV6.num);
                }
            })
        },
        changeAiSettingChatgpt(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingChatgpt', data);
        },
        changeAiSettingChatgptModel(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingChatgptModel', data);
        },
        changeAiSettingChatgptApi(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingChatgptApi', data);
        },
        changeAiSettingTyqw(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingTyqw', data);
        },
        changeAiSettingTyqwModel(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingTyqwModel', data);
        },
        changeAiSettingTyqwApi(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingTyqwApi', data);
        },
        changeAiSettingMidjourneyV6(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingMidjourneyV6', data);
        },
        changeAiSettingMidjourneyV6Api(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingMidjourneyV6Api', data);
        },
        changeAiSettingMidjourneyV6Size(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingMidjourneyV6Size', data);
        },
        changeAiSettingMidjourneyV6Num(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingMidjourneyV6Num', data);
        },
        changeAiSettingMidjourneyV6Model(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setAiSettingMidjourneyV6Model', data);
        },
    }
};
</script>

<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/variable.css';

/* 滚动条 */
/* 修改滚动条的宽度 */
::-webkit-scrollbar {
    width: 6.24px;
}

/* 修改滚动条的背景色 */
::-webkit-scrollbar-track {
    background: #212121;
}

/* 修改滚动条滑块的颜色 */
::-webkit-scrollbar-thumb {
    background: #383838;
}

/* 修改滚动条滑块的颜色（鼠标悬停时） */
::-webkit-scrollbar-thumb:hover {
    background: #383838;
}

.content-bg {
    width: 1843.2px;
    height: 100%;
    background: #212121;
    position: relative;
}

.content-bg .modelState {
    position: absolute;
    right: 9.9994px;
    top: 9.9994px;
    padding: 3.9994px 8.0006px;
    background: #2f2f2f;
    border-radius: 3.001px;
    color: #abaaaa;
    font-family: 'YSBTH';
    border: 1.0003px solid rgba(255, 255, 255, 0.111);
}



.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 68.0006px 458.0006px 68.0006px 458.0006px;
    gap: 15.9994px;
}

.content .input {
    width: 100%;
    height: 10%;
    max-height: 20%;
    background: #1b1d1e;
    border-radius: 63.9994px;
    color: #aebcd8;
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 9.9994px 15.9994px 9.9994px 30px;
    transition: all .3s ease-in-out;
}

.input-border {
    border: 1.0003px solid #570284;
}

.content .input:hover {
    box-shadow: 3.84px 5.76px 1.92px .4243px #0000003d;
}


.input .input-btn {
    width: 50.0006px;
    height: 50.0006px;
    border-radius: 100%;
    background: #676767;
}

.input-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.input-btn img {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
}

.input .input-text {
    width: 90%;
    height: 100%;
    display: flex;
    padding: 9.9994px 0 10.9997px 9.9994px;
}

.input-text input[type="text"] {
    width: 100%;
    font-size: 20.0006px;
    color: #9B9B9B;
    font-family: 'LatoR';
}

/* 内容框 */

.content .elements {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.elements .element-item {
    width: 100%;
    height: 100%;
    padding: 9.9994px;
    display: flex;
    flex-direction: column;
    gap: 39.9994px;
}

.scrollable-container {
    max-height: 1152px !important;
    /* 设置最大高度为5个item的高度 */
    overflow-y: auto;
    /* 当内容超出容器高度时，显示垂直滚动条 */
}

.elements .item-request {
    width: 100%;
    height: auto;
    padding: 9.9994px 9.9994px 9.9994px 0;
    color: #c9c4bd;
    font-size: 20.0006px;
    font-family: 'LatoR';
    display: flex;
}

.item-request .item-content {
    width: auto;
    background: #2F2F2F;
    border-radius: 80.0006px;
    padding: 15.9994px 33.241px;
    margin-left: auto
}

.elements .item-response {
    width: 100%;
    height: auto;
    padding: 9.9994px;
    display: flex;
    flex-direction: row;
    gap: 9.9994px;
}

.item-response .item-response-logo {
    width: 43.2px;
    height: 43.2px;
}

.item-response-logo img {
    width: 100%;
    height: 100%;
    display: block;
}

.item-response .item-response-content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 7.2403px;
}

.item-response-content .item-response-content-text {
    width: 100%;
    height: auto;
    padding: 15.9994px;
    padding-top: 8.3194px;
    font-size: 18px;
    color: #c9c4bd;
    display: flex;
    gap: 13.0003px;
    line-height: 1.75;
}

.item-response-content .item-response-content-tools {
    width: 100%;
    height: 30px;
    display: flex;
}

.item-response-content-tools .tools-bg {
    width: auto;
    height: 100%;
    margin-left: auto;
    display: flex;
    padding: 0 27.9994px;
    gap: 13.0003px;
}

.item-response-content-tools .tools-item {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: background .3s ease-in-out;
    border-radius: 4.921px;
    padding: 3.0797px;
}

.tools-item img {
    width: 100%;
    height: 100%;
    display: block;
}

.tools-item:hover {
    background: #2f2f2f;
}

/* element-init */

.element-init-bg {
    background: #1b1d1e !important;
}

.element-init {
    width: 100%;
    height: 100%;
    padding: 9.9994px;
    display: flex;
    flex-direction: column;
    gap: 20.0006px;
}

.element-init .init-top {
    width: 100%;
    /* height: 390px; */
    display: flex;
    flex-direction: column;
    gap: 9.9994px;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.init-top-logo {
    width: 60px;
    height: 60px;
    background: #1b1d1e;
}

.init-top-logo img {
    width: 100%;
    height: 100%;
}

.init-top .init-top-title {
    font-family: 'YSBTH';
    font-size: 48px;
    color: #AFBDD9;
}

.init-top .init-top-text {
    font-family: 'LatoR';
    font-size: 20.0006px;
    color: #c0bbb3;
}


.element-init .init-bottom {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 9.9994px;
}

.init-bottom .init-bottom-btn {
    width: 100%;
    /* height: 45.9994px; */
    height: 13.3594px;
    display: flex;
    flex-direction: row-reverse;
}

.init-bottom-btn .init-bottom-btn-hyp {
    width: 126px;
    height: 45.9994px;
    /* 13.7107px = 15.9994px - 11.4432px - 11.4432px */
    /* 5.712px = 8.0006px - 11.4432px - 11.4432px */
    padding: 5.712px 13.7107px;
    background: #181A1B;
    display: flex;
    gap: 3.9994px;
    align-items: center;
    justify-items: center;
    border-radius: 11.4432px;
    color: #C0BBB3;
    cursor: pointer;
}

.init-bottom-btn .init-bottom-btn-hyp:hover {
    color: #5f91ed;
    border: 1.0003px solid #161375;
}

.init-bottom-btn-hyp .hyp-ico {
    width: 30px;
    height: 30px;
    color: #5f91ed;
}

.hyp-ico img {
    width: 100%;
    height: 100%;
}

.init-bottom-btn-hyp .hyp-text {
    font-size: 20.0006px;
    font-family: 'LatoR';
}

.init-bottom-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 9.6801px;
}

.init-bottom-content .bottom-content-left {
    width: 100%;
    height: 100%;
    background: #181a1b;
    display: flex;
    flex-direction: column;
    gap: 9.9994px;
    padding: 4.1606px;
}

.bottom-content-left .bottom-content-left-title {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 14.0006px;
    padding: 9.9994px 24px;
    justify-items: center;
    align-items: center;
}

.bottom-content-left-title .bottom-content-left-title-ico {
    width: 27.9994px;
    height: 27.9994px;
}


.bottom-content-left-title-ico img {
    width: 100%;
    height: 100%;
}

.bottom-content-left-title .bottom-content-left-title-content {
    font-size: 20.0006px;
    font-family: 'LatoB';
    color: #AFBDD9;
}

.bottom-content-left .bottom-content-left-content {
    width: 100%;
    height: 100%;
    padding: 9.9994px 24px;
    display: flex;
    flex-direction: column;
    gap: 14.0006px;
}

.bottom-content-left-content .bottom-content-left-content-item {
    width: 100%;
    height: 42px;
    display: flex;
    gap: 12px;
    padding: 9.9994px;
    justify-items: center;
    align-items: center;
    background: #1A1C1E;
    color: #EBEAE7;
    cursor: pointer;
}

.bottom-content-left-content-item .point {
    width: 9.9994px;
    height: 9.9994px;
    border-radius: 100%;
    background: #141090;
    border-radius: 12px;
}

.bottom-content-left-content-item .bottom-content-left-content-item-text {
    font-size: 18px;
    font-family: 'LatoB';
}

.bottom-content-left-content-item:hover {
    color: #5f91ed;
    border: 1.0003px solid #161375;
}



.init-bottom-content .bottom-content-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 8.0006px;
    gap: 36px;
}

.bottom-content-right .bottom-content-right-item {
    width: 411.001px;
    height: 100%;
    background: #181A1B;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 3.9994px;
    padding: 9.9994px 24px;
    cursor: pointer;
}

.bottom-content-right-item .bottom-content-right-title {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 14.0006px;
    justify-items: center;
    align-items: center;
}

.bottom-content-right-title .bottom-content-right-title-ico {
    width: 27.9994px;
    height: 27.9994px;
}

.bottom-content-right-title-ico img {
    width: 100%;
    height: 100%;
}

.bottom-content-right-title .bottom-content-right-title-content {
    font-size: 20.0006px;
    font-family: 'LatoB' !important;
    color: #AFBDD9;
}

.bottom-content-right .bottom-content-right-text {
    font-size: 18px;
    color: #A8A095;
    font-family: 'LatoR';
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 超出部分用省略号替代 */
}

.bottom-content-right-item:hover {
    color: #5f91ed;
    border: 1.0003px solid #161375;
}
</style>