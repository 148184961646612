<template>
  <div class="content-bg">
    <div class="content">
      <TableCommonComponent :tableCommonDataList="tableCommonDataListCom" @dialogVisible="handleDialogVisible"
        @editForm="handleEditForm" @delete="deleteRow" />
      <div v-if="dialogVisible" class="dialog-overlay">
        <div class="dialog">
          <div class="title">
            更新数据
          </div>
          <div class="items">
            <div class="item">
              <label>
                Title:
              </label>
              <input v-model="editForm.title" />
            </div>
            <div class="item">
              <label>
                Alt:
              </label>
              <input v-model="editForm.alt" />
            </div>
            <div class="item">
              <label>
                Icon:
              </label>
              <input v-model="editForm.ico" />
            </div>
            <div class="item">
              <label>
                IconID:
              </label>
              <input v-model="editForm.icoID" />
            </div>
            <div class="item">
              <label>
                Link:
              </label>
              <input v-model="editForm.link" />
            </div>
          </div>

          <div class="btn">
            <button class="custom-button" @click="saveEdit">Save</button>
            <button class="custom-button" @click="dialogVisible = false">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableCommonComponent from '../../components/TableCommonComponent.vue';

import request from '@/http/request';
import { notificationTip } from '../../utils/messageTip.js'
import { mapState } from 'vuex';

export default {
  name: 'DataCommonContentView',
  components: {
    TableCommonComponent
  },
  data() {
    return {
      dialogVisible: false,
      dataSelect: 0,
      editForm: {
        id: '',          // 与表格中的 id 字段保持一致
        title: '',       // 与表格中的 title 字段保持一致
        alt: '',         // 与表格中的 alt 字段保持一致
        ico: '',         // 与表格中的 ico 字段保持一致
        icoID: '',       // 与表格中的 icoID 字段保持一致
        link: '',        // 与表格中的 link 字段保持一致
      },
      tableDataList: {},
      isDataLoaded: false  // 数据加载标志位
    }
  },
  mounted() {
    this.getTableListDataByAxios()
  },
  watch: {
    '$store.state.dataSelect': {
      handler(newValue) {
        this.dataSelect = newValue
      },
      immediate: true
    },
    '$store.state.dataParentSelect'(newValue, oldValue) {
      if (newValue === 2) {
        this.getTableListDataByAxios();
      }
    }
    ,
    '$store.state.select'(newValue, oldValue) {
      if (newValue === 4) {
        this.getTableListDataByAxios();
      }
    }
  },
  computed: {
    ...mapState({
      rightItemsContentSelect: state => state.rightItemsContentSelect
    }),
    tableCommonDataListCom() {
      // 首先检查 this.rightItemsContentSelect 是否有效
      if (!this.rightItemsContentSelect || !this.$store.state.tableDataList[this.rightItemsContentSelect]) {
        // 如果不存在有效的 this.rightItemsContentSelect 或者对应的数组，则返回空数组或者做出其他适当的处理
        return [];
      }
      // 返回根据 this.rightItemsContentSelect 选择的数组数据
      return this.$store.state.tableDataList[this.rightItemsContentSelect];
    }
  },
  methods: {
    async getTableListDataByAxios() {
      let res = await request.get("/api/tab/list");
      console.log("res", res);

      if (res && res.code === 200) {
        console.log("response.data", res.data);
        this.tableDataList = res.data
        this.isDataLoaded = true;  // 数据加载成功
        this.$store.commit('setTableDataList', res.data);
        this.$store.commit('setIsDataLoaded', true);
      } else {
        notificationTip("获取快捷启动栏数据", "数据获取失败!", "error")
      }

    },
    toggleDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    async deleteRow(itemId) {

      const url = '/api/tab/delete/' + itemId;
      let res = await request.delete(url);
      console.log("delete-res", res);

      if (res && res.code === 200) {
        this.getTableListDataByAxios();
      } else {
        notificationTip("删除快捷启动栏数据!", "删除失败!", "error")
      }

    },
    async updateRow() {
      let res = await request.put("/api/tab/update", this.editForm)
      console.log("edit-res", res);

      if (res && res.code === 200) {
        this.getTableListDataByAxios();
      } else {
        notificationTip("更新快捷启动栏数据!", "更新失败!", "error")
      }

    },
    handleDialogVisible(dialogVisible) {
      this.dialogVisible = dialogVisible;
    },
    handleEditForm(editForm) {
      this.editForm = editForm
    },
    saveEdit() {
      this.updateRow()
      this.dialogVisible = false;
    },
  }
};
</script>

<style scoped>
.content-bg {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 44.0006px;
  /* Apply blur effect */
}


.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* 确保遮罩层在对话框之上 */
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20.0006px;
  border-radius: 8.0006px;
  box-shadow: 0 2.0006px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 288px;
  max-width: 80%;
  /* 控制对话框最大宽度 */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 17.8406px;
}

.dialog .title {
  font-size: 15.9994px;
  color: #3D3D3D;
  font-family: 'SYHT-R';
  font-weight: bolder;
}

.dialog .items {
  width: 100%;
  display: flex;
  gap: 15.9994px;
  flex-direction: column;
}

.dialog .item {
  width: 100%;
  height: 30px;
  display: flex;
  gap: 15.9994px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}

.item label {
  display: block;
  margin-bottom: 9.9994px;
  line-height: 30px;
  font-family: 'SYHT-R';
}

.dialog input {
  width: 184.9997px;
  /* 减去padding */
  padding: 8.0006px;
  font-size: 1em;
  border: 1.0003px solid #ccc;
  border-radius: 3.9994px;
  box-sizing: border-box;
  outline: none;
}

.dialog .btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn button {
  height: 100%;
  padding: 8.0006px 15.9994px;
  font-size: 1em;
  border: none;
  border-radius: 3.9994px;
  background-color: #5b3442;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog button:hover {
  background-color: #0056b3;
}
</style>