<template>
    <div class="password-container">
        <input class="password-input" :type="showPassword ? 'password' : 'text'" v-model="internalPassword"
            name="textInputValue"  @input="updateKeyword" :readonly="isReadonly" />
        <i v-if="showPasswordIco" :class="['fas', !showPassword ? 'fa-eye-slash' : 'fa-eye', 'toggle-password']"
            @click="togglePasswordVisibility"></i>
    </div>
</template>
  
<script>
export default {
    name: 'KeyWordInputCommpent',
    data() {
        return {
            internalPassword: this.modelValue,
            showPassword: this.showPassword_,
            isReadonly: this.isReadonly_,
            showPasswordIco: this.showPasswordIco_
        }
    },
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        showPassword_: {
            type: Boolean,
            required: true,
            default: false
        },
        isReadonly_: {
            type: Boolean,
            required: true,
            default: false
        },
        showPasswordIco_: {
            type: Boolean,
            required: true,
            default: true
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        updateKeyword() {
            this.$emit('update-keyword', this.internalPassword);
        }
    },
    watch: {
        value(newValue) {
            this.internalPassword = newValue;
        }
    }
};
</script>
  
<style scoped>
.password-container {
    display: flex;
    align-items: center;
    width: 100%;
    /* 设置一个合适的最大宽度 */
    margin: 9.9994px 0;
    /* 添加边框以便更容易看到 */
    padding: 4.9997px;
    /* 添加一些内边距 */
    box-sizing: border-box;
    /* 确保边框和内边距包含在宽度和高度内 */
}

.password-input {
    flex: 1;
    padding: 8.0006px;
    /* 添加一些内边距以增加输入框的高度 */
    margin-right: 9.9994px;
    /* 确保图标不会遮挡输入框内容 */
    border: none;
    padding-right: 39.9994px;
    padding-left: 0;
    outline: none !important;
    /* 移除聚焦时的轮廓 */
    font-family: 'LatoR';
    font-size: 12px;
    color: #3D3D3D;
}


.toggle-password {
    cursor: pointer;
    margin-left: -30px;
    /* 根据需要调整 */
}
</style>