<template>
    <div>
        <el-drawer v-model="drawer" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false"
            :destroy-on-close="true" :show-close="false">
            <template #header="{ titleId, titleClass }">
                <div :class="titleClass"
                    style="display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center;">
                    <span style="display: block ; padding: 10px auto;">修改你的密码</span>
                    <div class="demo-progress" style="width: 100%">
                        <el-progress :percentage="progress.percentage" :status="progress.status" :stroke-width="10" />
                    </div>
                </div>
            </template>
            <div class="confirmContent" v-if="isShowInput">
                <el-form ref="inputRef" :model="input" :rules="rules" status-icon :scroll-to-error="true">
                    <!-- <div class="confiirm-item">
                        <el-form-item prop="oldPassword">
                            <el-input v-model="input.oldPassword" type="password" placeholder="请输入旧密码" show-password />
                        </el-form-item>
                    </div> -->
                    <div class="confiirm-item">
                        <el-form-item prop="newPassword">
                            <el-input v-model="input.newPassword" type="password" placeholder="请输入新密码" show-password />
                        </el-form-item>
                    </div>
                    <div class="confiirm-item">
                        <el-form-item prop="secondPassword">
                            <el-input v-model="input.secondPassword" type="password" placeholder="请再次输入新密码" show-password />
                        </el-form-item>
                    </div>
                </el-form>
            </div>

            <div class="resultContent">
                <el-result v-if="!isShowInput && isSuccess" icon="success" title="Success Tip"
                    sub-title="Please follow the instructions">
                </el-result>

                <el-result v-if="!isShowInput && !isSuccess" icon="error" title="Error Tip"
                    sub-title="Please follow the instructions">
                </el-result>
            </div>

            <template #footer>
                <el-button type="primary" @click="confirmClick()" :disabled="progress.percentage !== 100"
                    :class="{ 'el-button-disabled': progress.percentage !== 100 }">修改</el-button>
            </template>
        </el-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { notificationTip, notificationTipTime } from '../utils/messageTip.js'
import request_ from '@/http/request';

export default {
    name: 'InitModifyPassword',
    data() {

        const validateOldPassword = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入老密码'))
            } else {
                // if (this.input.oldPassword !== '') {
                //     this.$refs.inputRef.validateField('oldPassword');
                // }
                callback()
            }
        }
        const validateNewPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'))
            } else if (this.input.newPassword.length < 6 || this.input.newPassword.length > 12) {
                callback(new Error('密码长度不能小于6或大于12'))
            } else {
                callback();
            }
        }
        const validateRepeatPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.input.newPassword) {
                callback(new Error("两次输入密码不匹配"))
            } else {
                if (this.input.secondPassword.length < 6 || this.input.secondPassword.length > 12) {
                    callback(new Error('密码长度不能小于6或大于12'))
                }
                callback()
            }
        }

        return {
            input: {
                oldPassword: "",
                newPassword: "",
                secondPassword: "",
            },
            rules: {
                oldPassword: [
                    { validator: validateOldPassword, trigger: 'blur', required: true }
                ],
                newPassword: [
                    { validator: validateNewPassword, trigger: 'blur', required: true }
                ],
                secondPassword: [
                    { validator: validateRepeatPassword, trigger: 'change', required: true }
                ],
            },
            progress: {
                percentage: 0,
                status: "exception",
                hasIncreased: {
                    oldPassword: false,
                    newPassword: false,
                    secondPassword: false,
                }
            },
            isShowInput: true,
            isSuccess: false
        };
    },
    created() {
        this.input = {
            oldPassword: "",
            newPassword: "",
            secondPassword: "",
        }
        this.isShowInput = true
        this.isSuccess = false

        this.progress = {
            percentage: 0,
            status: "exception",
            hasIncreased: {
                oldPassword: false,
                newPassword: false,
                secondPassword: false,
            }
        }
    },
    computed: {
        ...mapState(['drawer'])
    },
    methods: {
        handleClose() {
            this.$store.commit('setDrawer', false); // 关闭抽屉
        },
        cancelClick() {
            this.handleClose();
        },
        async confirmClick() {
            const valid = await new Promise((resolve) => {
                this.$refs['inputRef'].validate(resolve)
            })

            if (valid) {
                // 提交修改密码
                let user = this.$store.state.userInfo
                user.passWord = this.input.newPassword
                let res = await request_.patch("/api/user/init/password", user);

                console.log("initPwd-res", res);

                if (res && res.code === 200) {
                    // 处理确认逻辑
                    this.isShowInput = false;
                    this.isSuccess = true;

                    this.$store.commit('setUser', res.data); // 更新至vuex
                    console.log("vuex.userInfo", this.$store.state.userInfo);
                    setTimeout(() => {
                        this.handleClose();
                    }, 1500);
                } else {
                    this.isShowInput = false;
                    this.isSuccess = false;
                }
            }
        },
        updateProgress(newVal, oldVal, field) {
            const increment = 50; // 定义增量为 33%
            // 增加进度
            if (newVal.length > oldVal.length && !this.progress.hasIncreased[field]) {
                this.progress.percentage = Math.min(this.progress.percentage + increment, 100);
                this.progress.hasIncreased[field] = true; // 标记已增加
            }
            // 删除内容并且文本框为空时减少进度
            else if (newVal.length < oldVal.length && newVal.length === 0 && this.progress.hasIncreased[field]) {
                this.progress.percentage = Math.max(this.progress.percentage - increment, 0);
                this.progress.hasIncreased[field] = false; // 标记已减少
            }
            // 如果进度为66%
            if (this.progress.percentage === 50) {
                this.progress.status = "warning"
            } else if (this.progress.percentage < 50) {
                this.progress.status = "exception"
                if (this.progress.percentage === 1) {
                    this.progress.percentage = 0
                }
            } else if (this.progress.percentage === 100) {
                this.progress.status = "success"
            }
        }
    },
    watch: {
        drawer(newVal) {
            if (newVal) {
                this.drawer = true; // 打开抽屉
            }
        },
        'input.oldPassword'(newVal, oldVal) {
            this.updateProgress(newVal, oldVal, 'oldPassword');
        },
        'input.newPassword'(newVal, oldVal) {
            this.updateProgress(newVal, oldVal, 'newPassword');
        },
        'input.secondPassword'(newVal, oldVal) {
            this.updateProgress(newVal, oldVal, 'secondPassword');
        }
    },
};
</script>


<style scoped>
:deep .el-drawer__title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}


:deep .el-progress__text {
    min-width: auto;
}

:deep .el-form-item__error {
    font-size: 18px;
}

:deep .el-icon {
    font-size: 24px;
}

:deep .el-input__wrapper {
    border-radius: 10px;
}

.el-drawer .el-button {
    color: #fff;
    background: #598360;
    transition: all 1s ease-in-out;
    border: none;
    width: 80px !important;
    height: 80px !important;
    border-radius: 100% !important;
    font-size: 14px;
    box-shadow: 0 2px 8px #2c3e505f;
}

.el-button-disabled {
    background: #6b6b6b !important;
}


.confirmContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0 60px;
    /* border: 2px solid #dbdbdb; */
    border-radius: 20px;
    /* box-shadow: 0 2px 8px #2c3e505f; */
}

.resultContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
    border-radius: 20px;
}

.confirmContent .el-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: baseline;
    gap: 30px;
    border-radius: 20px;
}

.el-form .confiirm-item {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2c3e50;
    font-size: 18px;
}


.confiirm-item .el-form-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.el-form-item .el-input {
    width: 100%;
    height: 60px;
    font-size: 18px;
}
</style>