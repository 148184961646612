<template>
    <div>
      <h1>WebSocket Chat</h1>
      <input v-model="message" placeholder="Enter your message" />
      <button @click="sendMessage">Send</button>
      <ul>
        <li v-for="(msg, index) in messages" :key="index">{{ msg.content }}</li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "WebSocketView",
    data() {
      return {
        message: '',
        messages: []
      };
    },
    mounted() {
      // 使用全局WebSocket实例连接
      this.$websocket.connect(this.handleMessage);
    },
    beforeUnmount() {
      // 组件销毁时断开连接
      this.$websocket.disconnect();
    },
    methods: {
      handleMessage(message) {
        // 接收消息后，将消息推入messages数组中
        this.messages.push(message);
      },
      sendMessage() {
        // 发送消息
        this.$websocket.sendMessage(this.message);
        this.message = '';
      }
    }
  };
  </script>
  