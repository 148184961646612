<template>
  <span v-html="formattedText"></span>
</template>

<script>
import { marked } from 'marked';

export default {
  name: "AnimatedText",
  props: {
    text: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      default: 'ltr', // 默认从左到右
      validator: value => ['ltr', 'rtl'].includes(value) // 验证输入值
    }
  },
  data() {
    return {
      displayedText: '',
      formattedText: '' // 用于渲染带有换行符和 Markdown 转换后的文本
    };
  },
  watch: {
    text: {
      immediate: true,
      handler(newText) {
        this.displayedText = '';
        this.formattedText = '';
        this.showText(newText);
      }
    }
  },
  methods: {
    showText(text) {
      let index = this.direction === 'ltr' ? 0 : text.length - 1;
      const step = this.direction === 'ltr' ? 1 : -1;
      const interval = setInterval(() => {
        if (text[index] === '\n') {
          this.displayedText += '\n';
        } else {
          this.displayedText += text[index];
        }

        // 使用 marked 解析 Markdown 为 HTML
        this.formattedText = marked(this.displayedText);
        
        index += step;
        if (index < 0 || index >= text.length) {
          clearInterval(interval);
        }
      }, 50); // 每50ms显示一个字符
    }
  }
};
</script>
