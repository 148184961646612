<template>
    <div class="authContainer">
        <div class="content-bg">
            <div class="content">
                <div class="content-left">
                    <div class="content-left-item" :style="{ backgroundImage: 'url(' + bgImage.url + ')' }"
                        :class="{ 'opacity-100': !QR.isQR, 'opacity-0': QR.isQR }" v-if="bgImage.isLoading"></div>

                    <!-- 图片未加载完成 -->
                    <div class="content-left-item" v-else="bgImage.isLoading"
                        :class="{ 'opacity-100': !QR.isQR, 'opacity-0': QR.isQR }">
                        <div class="left-item-loading">
                            <svg class="icon" aria-hidden="true" id="auth-image-load">
                                <use xlink:href="#icon-jiazai"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="content-left-item" :class="{ 'opacity-100': QR.isQR, 'opacity-0': !QR.isQR }">
                        <div class="left-item-title" @click="QR.isExpired = !QR.isExpired">扫描二维码登录</div>
                        <div class="left-item-QR">
                            <img :src="QR.QRImage" alt="">
                            <div class="item-QR-expired"
                                :class="{ 'opacity-100': QR.isExpired, 'opacity-0': !QR.isExpired }">
                                <div class="QR-expired-title">二维码失效</div>
                                <div class="QR-expired-btn" @click="flushQR()">刷新二维码</div>
                            </div>
                        </div>
                        <div class="left-item-introduce">请使用<a href="#">FastPage客户端</a>扫描登录或扫描下载APP</div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-right-content">
                        <div class="right-content-logo">
                            <img src="https://data-1310058445.cos.ap-guangzhou.myqcloud.com/svg/FastPage.svg" alt="">
                        </div>
                        <div class="right-content-nav">
                            <div class="content-nav-item content-nav-default"
                                :class="{ 'nav-item-active': selectNav === 'default' }" @click="switchNav('default')">
                                账户密码登录
                            </div>
                            <div class="content-nav-item content-nav-phone"
                                :class="{ 'nav-item-active': selectNav === 'phone' }" @click="switchNav('phone')">手机号登录
                            </div>
                        </div>
                        <div class="right-content-inputs" v-if="selectNav === 'default'">
                            <div class="content-inputs-account-phone">
                                <div class="content-icon inputs-account-phone-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-zhanghaoziliaoguanli"></use>
                                    </svg>
                                </div>
                                <div class="content-input inputs-account-phone-input">
                                    <input type="text" v-model="defaultForm.accountPhone" placeholder="用户名/手机号/邮箱">
                                </div>
                            </div>
                            <div class="content-inputs-password-code">
                                <div class="content-icon nputs-password-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-mima"></use>
                                    </svg>
                                </div>
                                <div class="content-input inputs-password-input">
                                    <input type="password" v-model="defaultForm.passwordCode" placeholder="密码"
                                        v-if="defaultForm.isEyes">
                                    <input type="text" v-model="defaultForm.passwordCode" placeholder="密码"
                                        v-else="defaultForm.isEyes">
                                    <div class="password-input-other">
                                        <div class="eyes" @click="changeIsEyes()">
                                            <svg class="icon" aria-hidden="true" v-if="defaultForm.isEyes">
                                                <use xlink:href="#icon-yincang"></use>
                                            </svg>
                                            <svg class="icon" aria-hidden="true" v-else="defaultForm.isEyes">
                                                <use xlink:href="#icon-chakan"></use>
                                            </svg>
                                        </div>
                                        <div class="forget-password">
                                            <a href="#">忘记密码?</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right-content-inputs" v-if="selectNav === 'phone'">
                            <div class="content-inputs-account-phone">
                                <div class="content-icon inputs-account-phone-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-huatong"></use>
                                    </svg>
                                </div>
                                <div class="content-input inputs-account-phone-input">
                                    <input type="text" v-model="phoneForm.accountPhone" placeholder="手机号">
                                </div>
                            </div>
                            <div class="content-inputs-password-code">
                                <div class="content-icon nputs-password-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-yanzhengma"></use>
                                    </svg>
                                </div>
                                <div class="content-input inputs-password-input">
                                    <input type="text" v-model="phoneForm.passwordCode" placeholder="验证码">
                                    <div class="password-input-other">
                                        <div class="code-btn" @click="toSMS()">
                                            <div class="code-btn-text">
                                                {{ phoneForm.text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="inputs-buttons">
                            <div class="buttons-register" @click="register()" v-show="selectNav !== 'phone'">注册</div>
                            <div class="buttons-login" @click="login()" v-show="selectNav !== 'phone'">登录</div>
                            <div class="buttons-login" @click="phoneLoginOrRegister()" v-show="selectNav === 'phone'">登录/注册
                            </div>
                        </div>
                        <div class="right-content-other">
                            <div class="content-other-text">其他方式登录</div>
                            <div class="content-other-content">
                                <div class="other-content-item" @click="otherLogin('wechat')">
                                    <div class="content-item-icon">
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-weixin"></use>
                                        </svg>
                                    </div>
                                    <!-- <div class="content-item-text">
                                        <a href="#">微信登录</a>
                                    </div> -->
                                </div>
                                <div class="other-content-item" @click="otherLogin('google')">
                                    <div class="content-item-icon">
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-guge"></use>
                                        </svg>
                                    </div>
                                    <!-- <div class="content-item-text">
                                        <a href="#">Google登录</a>
                                    </div> -->
                                </div>
                                <div class="other-content-item" @click="otherLogin('qq')">
                                    <div class="content-item-icon">
                                        <svg class="icon" aria-hidden="true">
                                            <use xlink:href="#icon-QQ"></use>
                                        </svg>
                                    </div>
                                    <!-- <div class="content-item-text">
                                        <a href="#">
                                            QQ登录
                                        </a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="exempt-statemen">
                        登录或完成注册视为您已同意第三方账号
                        <a href="#" @click="getProtocol('bing')">绑定协议</a>、
                        <a href="#" @click="getProtocol('user')">用户协议</a>、
                        <a href="#" @click="getProtocol('privacy')">隐私政策</a>
                    </div>
                </div>

                <div class="content-nav">
                    <div class="content-QR" @click="QR.isQR = !QR.isQR">
                        <svg class="icon" aria-hidden="true">
                            <use xlink:href="#icon-erweima"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import QRCode from 'qrcode'

import { onImageSwitch } from '../../utils/common.js'
import { messageTip } from '../../utils/messageTip.js'
import { notificationTip, notificationTipTime } from '../../utils/messageTip.js'
import request_ from '@/http/request';

export default {
    name: 'LoginView',

    data() {
        return {
            bgImage: {
                url: "https://data-1310058445.cos.ap-guangzhou.myqcloud.com/bg-image/image_2.jpg",
                isLoading: false
            },
            defaultForm: {
                accountPhone: "",
                passwordCode: "",
                isEyes: true, // 默认不可见
            },
            phoneForm: {
                accountPhone: "",
                passwordCode: "",
                text: "获取验证码"
            },
            selectNav: "phone",
            isVerificationCode: false,
            QR: {
                QRImage: "",
                isQR: false,
                info: "二维码内容",
                isExpired: false
            }

        };
    },

    mounted() {
        let newImageUrl = onImageSwitch();
        this.loadImage(newImageUrl);
    },
    watch: {
        'QR.isQR'(newVal, oldVal) {
            if (!oldVal && newVal) {
                // 生成QR
                this.generateQRCode()
            }

        }
    },
    methods: {
        // 账号密码登录 / 手机号登录 切换
        switchNav(type) {
            if (type === 'default') {
                this.selectNav = 'default'
                this.defaultForm = {
                    accountPhone: "",
                    passwordCode: "",
                    isEyes: true, // 默认不可见
                }
            } else if (type === 'phone') {
                this.selectNav = 'phone'
                this.phoneForm = {
                    accountPhone: "",
                    passwordCode: "",
                    text: "获取验证码"
                }
            }
        },
        onImageSwitch() {
            let randomNum = Math.floor(Math.random() * 37) + 1;
            let newImageUrl = "https://data-1310058445.cos.ap-guangzhou.myqcloud.com/bg-image/image_" + randomNum + ".jpg"

            // 创建一个 Image 对象
            let img = new Image();

            // 设置图片的加载完成事件
            img.onload = () => {
                this.bgImage = newImageUrl;
            };

            // 设置图片的加载错误事件（可选）
            img.onerror = () => {
                alert("图片加载失败");
            };

            // 触发图片加载
            img.src = newImageUrl;
        },
        changeIsEyes() {
            this.defaultForm.isEyes = !this.defaultForm.isEyes
        },
        toSMS() {
            // 进行手机号格式验证
            if (!this.isVerificationCode) {
                let phone = this.phoneForm.accountPhone;

                // 倒计时60s
                let timeLeft = 60;
                this.countdown = setInterval(() => {
                    timeLeft--;
                    this.phoneForm.text = `${timeLeft}s`;

                    if (timeLeft <= 0) {
                        clearInterval(this.countdown);
                        this.phoneForm.text = "获取验证码";
                        this.isVerificationCode = false
                    }
                }, 1000)

                // 发起获取验证码请求
                this.getVeriCode(phone)
            }
        },
        generateQRCode() {
            let QRInfo = this.QR.info + Date.now();
            QRCode.toDataURL(QRInfo, {
                version: 11, // 设置二维码版本为11: 61*61
                errorCorrectionLevel: 'H' // 设置纠错级别（L, M, Q, H）
            }).then(url => {
                this.QR.QRImage = url;
            }).catch(error => {
                console.err(error);
            })
        },
        // 注册
        register() {
            this.selectNav = 'phone'
        },
        // 登录
        async login() {
            let type = "";
            let res = null;
            // 账号密码登录
            if (this.defaultForm.passwordCode) {
                type = "default";
                res = await request_.post("/api/auth/login", {
                    "account": this.defaultForm.accountPhone,
                    "passWord": this.defaultForm.passwordCode
                })
            }

            // 手机号码登录
            if (this.phoneForm.passwordCode) {
                type = "phone";
            }
            
            console.log("login-res", res);

            if (res && res.code === 200) {
                notificationTip("登录结果", "登录成功!", "success")
                console.log("res", res);
                // 存入vuex
                let user = {
                    userId: res.data.user.userId,
                    account: res.data.user.account,
                    userName: res.data.user.userName,
                    ipAddr: res.data.user.ipAddr,
                    ipVesion: res.data.user.ipVesion,
                    local: res.data.local,
                    token: res.data.token,
                    avatarUrl: res.data.user.avatarUrl,
                    email: res.data.user.email,
                    phone: res.data.user.phone,
                    login_method: res.data.user.login_method,
                    status: res.data.user.status,
                    isNeedPassword: res.data.user.isNeedPassword,
                    lastLoginTime: res.data.user.lastLoginTime,
                    createdAt: res.data.user.createdAt
                }
                // console.log("user", user);
                this.changeUser(user) // 更新至vuex
                console.log("vuex.userInfo", this.$store.state.userInfo);

                // 跳转到首页
                this.$store.commit('setSelect', 1);
                this.$router.push("/")
            } else if (!res) {
                notificationTipTime("登录结果", "登录失败!", "error", 2000)
            } else if (res.code === 500) {
                notificationTipTime("登录结果", res.msg, "error", 2000)
            }

        },
        // 获取验证码
        async getVeriCode(phone) {
            let res = await request_.get(`/api/veri/generator/${phone}/code`)
            console.log("res", res);
            if (res && res.code === 200) {
                notificationTipTime("获取短信验证码", "验证码已发送,10分钟后过期!", "success")
                this.isVerificationCode = true
            } else {
                notificationTipTime("获取短信验证码", res.msg, "error")
                this.isVerificationCode = false
            }
        },
        async phoneLoginOrRegister() {
            // 判断是否有获取验证码
            if (this.phoneForm && this.phoneForm.accountPhone && this.phoneForm.passwordCode) {
                // 进行登录
                let data = {
                    "phone": this.phoneForm.accountPhone,
                    "code": this.phoneForm.passwordCode
                }
                let res = await request_.post("/api/auth/phone/login/register", data)
                console.log("res", res);
                if (res && res.code === 200) {
                    notificationTipTime("手机号码登录/注册", res.msg, "success")
                    // 存入Vuex
                    this.changeUser(res.data)

                    // 跳转到首页
                    this.$store.commit('setSelect', 1);
                    this.$router.push("/")
                } else {
                    notificationTipTime("手机号码登录/注册", res.msg, "error", 2000)
                }
            } else {
                notificationTipTime("手机号码登录/注册", "请先获取验证码", "error", 2000)
            }

        },
        // 其他登录方式
        otherLogin(type) {
            alert("登录方式-" + type)
        },
        // 获取隐私协议
        getProtocol(type) {
            alert("获取" + type + "协议")
        },
        // 刷新二维码
        flushQR() {
            if (this.QR.isExpired) {
                this.generateQRCode()
                this.QR.isExpired = false
            }
        },
        loadImage(newImageUrl) {
            // 创建一个 Image 对象
            const img = new Image();

            // 设置图片的加载完成事件
            img.onload = () => {
                this.bgImage.url = newImageUrl; // 设置背景图 URL
                this.bgImage.isLoading = true; // 加载完成，更新状态
            };

            // 设置图片的加载错误事件（可选）
            img.onerror = () => {
                alert("图片加载失败");
                this.bgImage.isLoading = false; // 加载失败，更新状态
            };

            // 触发图片加载
            img.src = newImageUrl;
        },
        changeUser(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setUser', data);
        },
    },
};
</script>

<style scoped>
@import url("../../assets//css/variable.css");
@import url("../../assets/css/icon.css");

* {
    /* transition: all .3s ease-in-out */
    user-select: none;
}

.opacity-0 {
    opacity: 0;
}

.opacity-100 {
    opacity: 1;
}

.authContainer {
    width: 100vw;
    height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    background-color: transparent;
}

.content-bg {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.284);
    backdrop-filter: blur(5.0003px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 1045px;
    height: 605px;
    border-radius: 20px;
    background: #FCFBFC;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    position: relative;
}

.content .content-nav {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #999;
    transition: color .4s ease-in;
}

.content-nav .content-QR {
    font-size: 36px;
}

.content .content-QR:hover {
    color: #c8c8c8;
    cursor: pointer;
}

/* .content-left */
.content .content-left {
    width: 606px;
    height: 605px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
}

.content-left .content-left-item {
    position: absolute;
    width: 606px;
    height: 605px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: all .5s ease-in-out;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.content-left .content-left-item:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.content-left-item:nth-of-type(1) .left-item-loading {
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgb(248, 248, 248);
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-item-loading svg {
    font-size: 50px;
    animation: svgLoad 2s linear infinite;
    /* 添加旋转动画 */
}

@keyframes svgLoad {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.content-left .content-left-item:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
}

.content-left-item:nth-of-type(2) .left-item-title {
    font-size: 24px;
    font-family: 'Alibaba Sans';
    font-weight: 700;
    color: #999999;
}

.content-left-item:nth-of-type(2) .left-item-QR {
    position: relative;
    width: 262px;
    height: 262px;
    border-radius: 22px;
    padding: 8px 8px;
    background: #fff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-item-QR img {
    width: 100%;
    height: 100%;
}

.left-item-QR .item-QR-expired {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    width: 262px;
    height: 262px;
    border-radius: 22px;
    transition: opacity .3s ease-in-out;
}

.item-QR-expired .QR-expired-title {
    font-size: 18px;
    font-family: 'Alibaba Sans';
    font-weight: 700;
    color: #999;
}

.item-QR-expired .QR-expired-btn {
    width: 126px;
    height: 30px;
    background: #699C71;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 0;
    color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.content-left-item:nth-of-type(2) .left-item-introduce {
    font-size: 14px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #999999;
}

.left-item-introduce>a {
    color: #699C71;
    cursor: pointer;
}


/* .content-right */
.content .content-right {
    width: 439px;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 24px 52px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content-right .exempt-statemen {
    font-size: 11px;
    color: #999999;
    font-family: 'Alibaba Sans';
    font-weight: 400;
}

.exempt-statemen>a {
    color: #699c71;
    cursor: pointer !important;
}

.content-right .content-right-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 0 12px;
}

.content-right-content .right-content-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-content-logo img {
    width: 100%;
    height: 100%;
}

/* .right-content-nav */
.content-right-content .right-content-nav {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 0 28px;
}

.right-content-nav .content-nav-item {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #999;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    transition: border-bottom .1s ease-in-out;
}

.nav-item-active {
    border-bottom: 4px solid #699c71;
}



.content-right-content .right-content-inputs {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 2px solid #CCCED6;
}

.right-content-inputs .content-input {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.content-input input {
    padding: 0 !important;
    margin: 0 !important;
    color: #999 !important;
}

.right-content-inputs .content-icon {
    font-size: 20px;
    color: #CCCED6;
}

.right-content-inputs .content-inputs-account-phone {
    border-bottom: 2px solid #CCCED6;
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-content: center;
    line-height: 43px;
    padding: 0 16px;
}

.content-inputs-account-phone .inputs-account-phone-icon {}

.content-inputs-account-phone .inputs-account-phone-input {
    font-size: 16px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #CCCED6;
}

.right-content-inputs .content-inputs-password-code {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-content: center;
    line-height: 43px;
    padding: 0 16px;
}

.content-inputs-password-code .inputs-password-icon {}

.content-inputs-password-code .inputs-password-input {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.inputs-password-input input {
    width: 100%;
    display: inline-block;
    flex-grow: 1;
}

.inputs-password-input .password-input-other {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.password-input-other .eyes {
    font-size: 16px;
    color: #999;
    cursor: pointer;
}

.password-input-other .forget-password,
.forget-password>a {
    display: inline-block;
    font-size: 14px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #699C71;
    line-height: 48px;
}

.password-input-other .code-btn {
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid #999999;
    cursor: pointer;
}

.code-btn .code-btn-text {
    width: 100%;
    font-size: 14px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #999;
    text-align: center;
    transition: color .3s ease-in-out;
}

.code-btn .code-btn-text:hover {
    color: #699c71;
}


/* inputs-buttons */
.content-right-content .inputs-buttons {
    width: 100%;
    height: var(--inputs-buttons-height);
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.inputs-buttons .buttons-register {
    width: 100%;
    padding: var(--inputs-buttons-padding-top) 0px;
    border-radius: 6px;
    border: 1px solid #999999;
    color: #699C71;
    text-align: center;
    line-height: calc(var(--inputs-buttons-height) - calc(var(--inputs-buttons-padding-top) * 2));
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.inputs-buttons .buttons-register:hover {
    background: #699c71;
    color: #fff;
}

.inputs-buttons .buttons-login {
    width: 100%;
    padding: var(--inputs-buttons-padding-top) 0px;
    border-radius: 6px;
    background: #699c71;
    color: #fff;
    text-align: center;
    line-height: calc(var(--inputs-buttons-height) - calc(var(--inputs-buttons-padding-top) * 2));
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.inputs-buttons .buttons-login:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.content-right-content .right-content-other {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.right-content-other .content-other-text {
    font-size: 14px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #999999;
    user-select: none;
}

.right-content-other .content-other-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 14px;
    justify-content: center;
    align-items: center;
}

.content-other-content .other-content-item {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.other-content-item:nth-of-type(2) .content-item-icon {
    font-size: 32px;
}

.other-content-item:nth-of-type(3) .content-item-icon {
    font-size: 32px;
}

.other-content-item .content-item-icon {
    font-size: 28px;
    border-radius: 100%;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: box-shadow .3s ease-in;
    cursor: pointer;
}

.other-content-item .content-item-icon:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.other-content-item .content-item-text,
.content-item-text a {
    font-size: 18px;
    font-family: 'Alibaba Sans';
    font-weight: 400;
    color: #999999;
    user-select: none;
}

.content-item-text,
.content-item-text a:hover {
    color: #699c71;
}
</style>