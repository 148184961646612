<template>
  <div class="main-con">
    <div class="content">
      敬请期待
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailContentView',

  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';

.main-con {
  width: 1920px;
  height: calc(100vh - 56.0006px);
  background: #212121;
  backdrop-filter: blur(7.0003px);
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-con .content {
  width: 933.9994px;
  height: 76vh;
  padding: 10.9997px 307.0003px;
  border-radius: 37.0003px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15.9994px 24px;
  overflow: hidden;
  padding-bottom: 5vh;
  justify-items: center;
  align-items: center;
  font-size: 30px;
  font-weight: bolder;
  font-family: 'SYHT-B';
  color: #747474;
  user-select: none;
}
</style>