// src/websocket.js
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs'; // 使用 @stomp/stompjs

let stompClient = null;
let host_port = `http://localhost:8081/ws`;
export default {
  install(app) {
    app.config.globalProperties.$websocket = {
      connect(callback) {
        const socket = new SockJS(host_port); // 后端 WebSocket 端点
        stompClient = Stomp.over(socket);
        stompClient.connect({}, (frame) => {
          console.log('Connected: ' + frame);
          stompClient.subscribe('/topic/messages', (message) => {
            if (callback) {
              callback(JSON.parse(message.body));
            }
          });
        });
      },
      disconnect() {
        if (stompClient !== null) {
          stompClient.disconnect();
          console.log('Disconnected');
        }
      },
      sendMessage(message) {
        if (stompClient && stompClient.connected) {
          stompClient.send('/app/sendMessage', {}, JSON.stringify({ content: message }));
        }
      },
    };
  }
};
