import axios from 'axios'
import router from "../router";
import store from "../store";

const request = axios.create({
    // baseURL: 'http://localhost:8081',
    baseURL: 'http://nekoya.xyz:8083',
    // timeout: 5000
})

// request 拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Device-Type'] = '2';
    config.headers['X-Device-Name'] = 'Browser';

    // 用户的token
    const userToken = store.state.userInfo.token;
    console.log("userToken = " + userToken);
    console.log("store.state.userInfo = ", store.state.userInfo);

    // 检查请求路径是否为 /api/tab/list
    if (config.url === '/api/tab/list') {
        // 如果是该请求，直接返回配置
        return config;
    }

    if (userToken) {
        config.headers['auth'] = `${userToken}`;
    } else {
        router.push("/login");
    }

    return config;
}, error => {
    return Promise.reject(error);
});


// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

