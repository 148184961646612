import { ElMessage } from 'element-plus'
import { ElNotification } from 'element-plus'

//success warning info error
export function messageTip(msg, type) {
    ElMessage({
        showClose: true, //是否客关部
        center: true, //文字是否居中
        message: msg,
        type: type,
        duration: 2000, //显示时间长度, 默认3000
    })
}

export function notificationTip(title, msg, type) {
    ElNotification({
        duration: 500,
        title: title,
        message: msg,
        type: type,
        position: 'bottom-right'
    })
}

export function notificationTipTime(title, msg, type, duration) {
    ElNotification({
        duration: duration,
        title: title,
        message: msg,
        type: type,
        position: 'bottom-right'
    })
}