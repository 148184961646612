<template>
    <div class="ColumnCon" :id="'ColumnCon-' + type" @click="hideContextMenu" ref="dropItems"
        @contextmenu.prevent="handleContextmenu($event, 'default', null)">
        <!-- @contextmenu.prevent="showCustomMenu(item.id)" -->
        <div class="column" v-for="item in new_data" @contextmenu.prevent="handleContextmenu($event, 'icon', item.id)">
            <a class="link" :href="edit.isEdit ? undefined : item.link" :title="item.title">
                <img :src="item.ico" :alt="item.alt">
                <span class="text" v-if="!edit.isEdit || edit.editId !== item.id">{{ item.title }}</span>
                <span class="text" v-if="edit.isEdit && edit.editId === item.id">
                    <input type="text" v-model="edit.editVal" :name="item.id" :id="item.id"
                        @keydown.enter="editSubmit(item.id, item.title, edit.editVal)">
                </span>
            </a>
            <!-- <div class="custom-menu" :id="'custom-menu-' + item.id">
                <div class="custom-menu-item" @click="menuItemClicked('upload', item.id)">
                    <div class="menu-item-ico">
                        <img src="../assets/images/svg/menu-item-ico-xiuGai.svg" alt="">
                    </div>
                    <div class="menu-item-text">修改</div>
                </div>
                <div class="custom-menu-item" @click="menuItemClicked('delete', item.id)">
                    <div class="menu-item-ico">
                        <img src="../assets/images/svg/menu-item-ico-delete.svg" alt="">
                    </div>
                    <div class="menu-item-text">删除</div>
                </div>
                <div class="custom-menu-item" @click="menuItemClicked('setting', item.id)">
                    <div class="menu-item-ico">
                        <img src="../assets/images/svg/menu-item-ico-setting.svg" alt="">
                    </div>
                    <div class="menu-item-text">设置</div>
                </div>
            </div> -->
        </div>

        <!-- <div class="columnAdd" @click="addItem()">
            <a class="link">
                <div class="addBtn">
                    <img src="../assets/images/svg/columnAdd-add.svg" alt="">
                </div>
            </a>
        </div> -->
        <div v-if="dialogVisible" class="dialog-overlay">
            <div class="dialog">
                <div class="title">
                    添加新标签
                </div>
                <div class="items">
                    <div class="item">
                        <label>
                            标题:
                        </label>
                        <input v-model="editForm.title" placeholder="不超过8个字" />
                    </div>
                    <div class="item">
                        <label>
                            介绍:
                        </label>
                        <input v-model="editForm.alt" placeholder="请输入介绍" />
                    </div>
                    <div class="item">
                        <label>
                            图标:
                        </label>
                        <input v-model="editForm.ico" placeholder="请输入图标地址" />
                    </div>
                    <div class="item">
                        <label>
                            icoID:
                        </label>
                        <input v-model="editForm.icoID" placeholder="请输入图标编号" />
                    </div>
                    <div class="item">
                        <label>
                            Link:
                        </label>
                        <input v-model="editForm.link" placeholder="请输入跳转地址" />
                    </div>
                </div>

                <div class="btn">
                    <button class="custom-button" @click="saveEdit">Save</button>
                    <button class="custom-button" @click="dialogVisible = false">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useMouse } from '@vueuse/core';

import Sortable from 'sortablejs';
import ContextMenu from "@imengyu/vue3-context-menu";

import request_ from '@/http/request';
import { notificationTip, notificationTipTime } from '@/utils/messageTip.js'


export default {
    name: "ContextMenu",
    data() {
        return {
            new_data: this.old_data,
            editForm: {
                id: '',          // 与表格中的 id 字段保持一致
                title: '',       // 与表格中的 title 字段保持一致
                alt: '',         // 与表格中的 alt 字段保持一致
                ico: '',         // 与表格中的 ico 字段保持一致
                icoID: '',       // 与表格中的 icoID 字段保持一致
                link: '',        // 与表格中的 link 字段保持一致
                type: ''
            },
            dialogVisible: false,
            edit: {
                editId: null,
                isEdit: false,
                editVal: ""
            },
            mouse: useMouse()
        };
    },
    props: {
        old_data: Array, // 假设data.common是一个数组
        type: String
    },
    mounted() {
        const el = this.$refs.dropItems;
        if (el) {
            Sortable.create(el, {
                animation: 300,
                onEnd: (evt) => {
                    const { oldIndex, newIndex } = evt;
                    const moveIndex = this.new_data.splice(oldIndex, 1)[0];
                    this.new_data.splice(newIndex, 0, moveIndex);
                }
            });
        }

        // 监听滚动事件
        this.updateMousePosition = this.updateMousePosition.bind(this);
        window.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.updateMousePosition);
    },
    watch: {
        old_data: {
            immediate: true,
            handler(newVal) {
                // 处理新的 old_data
                this.new_data = newVal
            }
        },
        'editForm.title': function (newVal, oldVal) {
            this.editForm.alt = newVal
            this.editForm.icoID = "ICO-" + newVal
        }
    },
    methods: {
        updateMousePosition(event) {
            this.mouse.x = event.clientX; // 使用clientX以确保在滚动时准确
            this.mouse.y = event.clientY; // 使用clientY以确保在滚动时准确
        },
        async saveEdit() {
            console.log("editForm", this.editForm);
            console.log('type', this.type);
            this.editForm.type = this.type

            let res = await request_.post("/api/tab/add", this.editForm)
                .then(response => {
                    console.log(res);
                    this.$emit('update', true);
                    this.dialogVisible = false
                })
                .catch(error => {
                    console.log('error', error);
                    this.dialogVisible = false
                });

        },
        addItem() {
            this.dialogVisible = true
        },
        showCustomMenu(itemId) {
            console.log("itemId", itemId);
            // 隐藏其他菜单
            this.hideAllCustomMenus();
            // 获取指定 item 的 custom menu 元素
            // const itemData = this.new_data.find(item => item.id === itemId);
            // if (itemData) {
            //     // Assuming the structure of each item in data has an 'isMenu' property
            //     itemData.isMenu = true;
            // }

            const customMenu = document.getElementById('custom-menu-' + itemId);
            // console.log("customMenu", customMenu);
            // 设置 display 属性为 block
            if (customMenu) {
                // console.log("xxx");
                customMenu.classList.add('show'); // 添加 .show 类
            } else {
                console.log("无法获取元素");
            }
        },
        hideAllCustomMenus() {
            // 隐藏所有的自定义菜单
            const customMenus = document.querySelectorAll('.custom-menu');
            customMenus.forEach(menu => {
                menu.classList.remove('show'); // 移除 .show 类
            });
        },
        menuItemClicked(msg, itemId) {
            if (msg === 'upload') {
                alert("修改===" + itemId)
                this.dialogFormUpload = true
                this.hideAllCustomMenus();

                this.$emit('sendDataToParent_AlertBox', { showAlert: true, itemId: itemId });

            } else if (msg === 'delete') {
                alert("删除===" + itemId)
                this.hideAllCustomMenus();
            } else if (msg === 'setting') {
                alert("设置===" + itemId)
                this.hideAllCustomMenus();
            }
        },
        hideContextMenu(event) {
            // 点击除自定义菜单以外的任何地方时隐藏自定义菜单
            if (!event.target.closest('.custom-menu')) {
                this.hideAllCustomMenus();
            }
        },
        handleContextmenu(e, type, id) {
            e.stopPropagation(); // 阻止事件冒泡
            console.log("e = ", e);
            const x_ = this.mouse.x; // Use VueUse's mouse position
            const y_ = this.mouse.y;
            console.log("type", type);
            if (type === 'default') {
                ContextMenu.showContextMenu({
                    theme: "mac dark",
                    x: x_,
                    y: y_,
                    items: [
                        {
                            label: "添加图标",
                            svgIcon: "#icon-tianjia",
                            onClick: () => {
                                this.addIcon();
                            },
                        },
                        {
                            label: "换壁纸",
                            svgIcon: "#icon-suijibofang",
                            onClick: () => {
                                this.switchImage();
                            },
                        },
                    ],
                });
            } else if (type === 'icon') {
                console.log("id = ", id);
                ContextMenu.showContextMenu({
                    theme: "mac dark",
                    x: x_,
                    y: y_,
                    items: [
                        {
                            label: "编辑",
                            svgIcon: "#icon-bianji",
                            onClick: () => {
                                this.editIcon(id);
                            },
                        },
                        {
                            label: "删除图标",
                            svgIcon: "#icon-shanchu",
                            onClick: () => {
                                this.delIcon(id);
                            },
                        },
                    ],
                });
            }
        },
        addIcon() {
            alert("添加图标");
        },
        switchImage() {
            alert("切换壁纸");
        },
        editIcon(id) {
            console.log("编辑图标-" + id);
            this.edit.editId = id;
            this.edit.isEdit = true;
            this.edit.editVal = this.new_data.find(item => item.id === id)?.title
        },
        delIcon(id) {
            // alert("删除图标-" + id);
            this.new_data = this.new_data.filter(item => item.id !== id);
        },
        editSubmit(id, oldVal, newVal) {
            // alert("图标-" + id + ", 原内容为-" + oldVal + ", 修改内容为-" + newVal)
            let item = this.new_data.find(item => item.id === id);
            if (item) {
                item.title = newVal
            }
            this.edit.editId = null;
            this.edit.isEdit = false;
        }
    }
};
</script>


  
<style>
@import url('../assets/iconfont/iconfont.css');

/* .mx-context-menu {
    width: 159.9994px;
    border-radius: 9.9994px !important;
    border: 1.0003px solid #333333 !important;
    padding: 3.2064px 0 !important;
}

.mx-context-menu-items {
    padding: 8.0006px 0 !important;
    display: flex !important;
    flex-direction: column;
}

.mx-context-menu-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mx-context-menu-item {
    width: 100% !important;
    height: 28.4006px !important;
    border-radius: 4.9997px !important;
    margin: 0px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 6px 15.001px !important;
}

.mx-item-row {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 3.9994px !important;
    justify-content: center;
    align-items: center;
}

.mx-item-row .preserve-width {
    width: 23.6794px !important;
}

.mx-item-row span.label {
    width: 100% !important;
    font-size: 15.9994px !important;
    padding: 0 !important;
    margin: 0 !important;
    user-select: none;
}

.dragging {
    opacity: 0.5;
} */


.ColumnCon {
    width: 1123.0003px;
    min-height: 45.001px;
    /* background: #000; */
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: dense;
    gap: 16.9997px 22.9997px;
    user-select: none !important;
}


.column {
    width: 168px;
    height: 45.001px;
    border-radius: 10.7597px;
    background: linear-gradient(135deg, #f6f6f6 0%, #e9e9e9 100%);
    box-shadow: 0px 3.9994px 8.0006px rgba(159, 57, 56, 0.1);
    color: #333;
    /* position: relative; */
    user-select: none !important;
    transition: all .6s ease;
}

/* 快捷方式元素动画 */
.column:hover {
    /* transform: translateY(-4.9997px); */
    box-shadow: 0px 9.9994px 20.0006px rgba(156, 65, 60, 0.2), 0px 6px 6px rgba(156, 65, 60, 0.1);
}

.conItem:nth-of-type(1) .column:nth-of-type(1) a {
    background-color: #66bb6a;

}

.conItem:nth-of-type(2) .column:nth-of-type(1) a {
    background-color: #7da4f1;
}

.conItem:nth-of-type(3) .column:nth-of-type(1) a {
    background-color: #eff17d;
}

.conItem:nth-of-type(4) .column:nth-of-type(1) a {
    background-color: #b77df1;
}

.conItem:nth-of-type(5) .column:nth-of-type(1) a {
    background-color: #26a69a;
}

.conItem:nth-of-type(6) .column:nth-of-type(1) a {
    background-color: #a72726;
}

.column a {
    width: 100%;
    height: 100%;
    display: flex;
    font-weight: bold;
    flex-direction: row;
    align-items: center;
    padding: 0 9.001px;
    column-gap: 9.001px;
    text-decoration: none;
    background: transparent;
    user-select: none;
    cursor: pointer;
    transition: color 0.3s ease, background-color 0.3s ease;
    user-select: none !important;
    border-radius: 10.7597px;
    /* 添加颜色过渡效果 */
}

.column img {
    border-radius: 100%;
    width: 33.001px;
    height: 33.001px;
    display: inline-block;
    border: 2.0006px solid #fff;
    background: #fff;
    margin-left: 6.24px;
    user-select: none !important;
}

.column .text {
    font-size: 12.96px;
    font-family: 'LatoR';
    user-select: none !important;
    /* 更改为深灰色 */
    display: -webkit-box;
    /* 使用旧的flex布局模型 */
    -webkit-box-orient: vertical;
    /* 设置为垂直排列 */
    overflow: hidden;
    /* 超出部分隐藏 */
    -webkit-line-clamp: 2;
    /* 限制显示的行数 */
}

.column:nth-child(1) .text {
    color: #fff;
}

.custom-menu {
    position: absolute;
    top: 30%;
    left: 30%;
    /* 使用fixed定位，以便与页面坐标相关 */
    z-index: -1;
    transition: opacity 0.3s ease;
    /* 添加 opacity 属性的过渡效果 */
    opacity: 0;
    /* 初始状态下设置透明度为 0 */

    width: 88.9997px;
    height: 156px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px 3.9994px;
    background: #B6504B;
    border-radius: 7.0003px;
    user-select: none !important;
}

.custom-menu .custom-menu-item {
    width: 100%;
    height: 44.0006px;
    display: flex;
    flex-direction: row;
    gap: 9.9994px;
    padding: 6px 15.9994px 6px 4.32px;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    transition: background .3s ease-in;
    cursor: pointer;
    border-radius: 9.001px;
}

.custom-menu-item:hover {
    background: #A04040 !important;
}


.custom-menu-item .menu-item-ico {
    width: 15.001px;
    height: 15.001px;
}

.menu-item-ico img {
    width: 100%;
    height: 100%;
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
}

.custom-menu-item .menu-item-text {
    font-size: 14.0006px;
    color: #FFFFFF;
    font-family: 'LatoB';
    font-weight: bold;
}



.show {
    opacity: 1;
    /* 当显示菜单时，透明度为 1 */
    z-index: 2000;
}


/* 添加按钮 */

.columnAdd {
    width: 168px;
    height: 45.001px;
    border-radius: 10.7597px;
    background: linear-gradient(135deg, #f6f6f6 0%, #e9e9e9 100%);
    box-shadow: 0px 3.9994px 8.0006px rgba(159, 57, 56, 0.1);
    transition: all 0.3s ease;
    color: #333;
    position: relative;
    user-select: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    /* 更改为深灰色 */
    cursor: pointer;
}

/* 快捷方式元素动画 */
.columnAdd:hover {
    transform: translateY(-4.9997px);
    box-shadow: 0px 9.9994px 20.0006px rgba(156, 65, 60, 0.2), 0px 6px 6px rgba(156, 65, 60, 0.1);
}


.link .addBtn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #8b403b;
}

.addBtn img {
    width: 100%;
    height: 100%;
}


.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    /* 半透明黑色遮罩层 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border-radius: 24px;
    /* 确保遮罩层在对话框之上 */
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20.0006px;
    border-radius: 8.0006px;
    box-shadow: 0 2.0006px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 288px;
    max-width: 80%;
    /* 控制对话框最大宽度 */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 17.8406px;
}

.dialog .title {
    font-size: 15.9994px;
    color: #3D3D3D;
    font-family: 'SYHT-R';
    font-weight: bolder;
}

.dialog .items {
    width: 100%;
    display: flex;
    gap: 15.9994px;
    flex-direction: column;
}

.dialog .item {
    width: 100%;
    height: 30px;
    display: flex;
    gap: 15.9994px;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
}

.item label {
    display: block;
    margin-bottom: 9.9994px;
    line-height: 30px;
    font-family: 'SYHT-R';
}

.dialog input {
    width: 184.9997px;
    /* 减去padding */
    padding: 8.0006px;
    font-size: 1em;
    border: 1.0003px solid #ccc;
    border-radius: 3.9994px;
    box-sizing: border-box;
    outline: none;
}

.dialog .btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.btn button {
    height: 100%;
    padding: 8.0006px 15.9994px;
    font-size: 1em;
    border: none;
    border-radius: 3.9994px;
    background-color: #5b3442;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dialog button:hover {
    background-color: #0056b3;
}
</style>
