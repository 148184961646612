<template>
  <div class="content-bg">
    <div class="content scrollable-container">
      <div class="item" v-for="item in items" :key="item.configurationId">
        <div class="item-top">
          {{ item.configurationTitle }}
        </div>
        <div class="item-bg">
          <div class="item-bg-content">
            <div class="item-bg-content-item" v-for="itemC in item.itemContent" :key="itemC.contentId">
              <div class="item-bg-content-item-text">
                {{ itemC.contentText }}
              </div>
              <div class="item-bg-content-item-content"
                :class="{ 'password-input-short': itemC.contentIsReadonly, 'password-input-readonly': itemC.contentIsReadonly }">
                <select class="item-bg-content-item-select" v-if="itemC.options.length !== 0"
                  v-model="itemC.contentInputValue" @change="handleSelectChange(item, itemC)">
                  <option v-for="itemCC in itemC.options" :key="itemCC.optionId" :value="itemCC.optionValue"
                    :selected="itemCC.isSelect">{{ itemCC.optionContent }}</option>
                </select>
                <KeyWordInputCommpent class="item-bg-content-item-textInput" v-else :modelValue="itemC.contentInputValue"
                  @update-keyword="updateTextInputValue(item.configurationId, itemC.contentId, $event)"
                  :showPassword_="itemC.contentShowPassword" :isReadonly_="itemC.contentIsReadonly"
                  :showPasswordIco_="itemC.contentShowPasswordIco" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="btn cancel" @click="settingCancel()">取消</div>
      <div class="btn save" @click="settingSave()">保存配置</div>
    </div>
  </div>
</template>

<script>
import KeyWordInputCommpent from '../../components/KeyWordInputCommpent.vue'

import request from '@/http/request';
import { notificationTip } from '../../utils/messageTip.js'

export default {
  name: 'DataAiContentView',
  components: {
    KeyWordInputCommpent
  },
  data() {
    return {
      items: []
    };
  },
  watch: {
    '$store.state.dataParentSelect'(newValue, oldValue) {
      if (newValue === 1) {
        this.getTableListDataByAxios();
      }
    },
    '$store.state.select'(newValue, oldValue) {
      if (newValue === 4) {
        this.getTableListDataByAxios();
      }
    }
  },
  computed: {
    internalKeyWord() {
      return "sk-lJHgqhgCXni8bEDvhE7bx8XKPtqxA0VmZzEeThHKpPLCaqBS";
    }
  },
  mounted() {
    this.getTableListDataByAxios();
  },
  methods: {
    setAiSettingTyqwModel(state, value) {
      state.aiSetting.tyqw.model = value;
    },
    settingCancel() {
      this.getTableListDataByAxios();
    },
    async settingSave() {
      this.items.forEach(item => {
        item.itemContent.forEach(itemC => {
          if (itemC.options.length > 0) {
            itemC.contentInputValue = ""; // 将contentInputValue设为空字符串
          }
        });
      });

      console.log("this.items", this.items);

      let res = await request.put("/api/ai/setting/update", this.items)
      console.log("edit-res", res);

      if (res && res.code === 200) {
        this.getTableListDataByAxios()
        notificationTip("修改AI助手配置", "修改成功!", "success")
      } else {
        notificationTip("修改AI助手配置", "修改失败!", "error")
      }

    },
    async getTableListDataByAxios() {

      let res = await request.get("/api/ai/setting/list")
      console.log("res", res);

      if (res && res.code === 200) {
        this.items = res.data;
        this.initializeSelectValues();
        notificationTip("初始化AI助手配置", "初始化成功!", "success")
      } else {
        notificationTip("初始化AI助手配置", "初始化失败!", "error")
      }


    },
    initializeSelectValues() {
      this.items.forEach(item => {
        item.itemContent.forEach(itemC => {
          if (itemC.options.length > 0) {
            const selectedOption = itemC.options.find(option => option.isSelect);
            if (selectedOption) {
              itemC.contentInputValue = selectedOption.optionValue;
            } else {
              // If no option is selected, default to the first option
              itemC.contentInputValue = itemC.options[0].optionValue;
              itemC.options[0].isSelect = true;
            }
          }
        });
      });

      this.items.forEach(item => {
        if (item.configurationTitle === "ChatGPT设置") {
          item.itemContent.forEach(itemC => {
            if (itemC.options.length > 0) {
              const selectedOption = itemC.options.find(option => option.isSelect);
              if (selectedOption) {
                this.changeAiSettingChatgptModel(selectedOption.optionValue);
              }
            } else {
              this.changeAiSettingChatgptApi(itemC.contentInputValue);
            }
          });
        } else if (item.configurationTitle === "通义千问设置") {
          item.itemContent.forEach(itemC => {
            if (itemC.options.length > 0) {
              const selectedOption = itemC.options.find(option => option.isSelect);
              if (selectedOption) {
                this.changeAiSettingTyqwModel(selectedOption.optionValue);
              }
            } else {
              this.changeAiSettingTyqwApi(itemC.contentInputValue);
            }
          });
        } else if (item.configurationTitle === "Midjourney V6设置") {
          this.changeAiSettingMidjourneyV6Api(item.itemContent[0].contentInputValue);
          this.changeAiSettingMidjourneyV6Size(item.itemContent[1].contentInputValue);
          this.changeAiSettingMidjourneyV6Num(item.itemContent[2].contentInputValue);
        }
      })
    },
    handleSelectChange(item, selectedContent) {
      // Update isSelect properties in options
      item.itemContent.forEach(itemC => {
        if (itemC === selectedContent) {
          itemC.options.forEach(option => {
            option.isSelect = option.optionValue === itemC.contentInputValue;
          });
        } else {
          itemC.options.forEach(option => {
            option.isSelect = false;
          });
        }
      });
    },
    updateTextInputValue(itemId, itemContentId, newValue) {
      const item = this.items.find(item => item.configurationId === itemId);
      if (item) {
        const itemContent = item.itemContent.find(content => content.contentId === itemContentId);
        if (itemContent) {
          itemContent.contentInputValue = newValue;
        }
      }
    },
    changeAiSettingChatgpt(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingChatgpt', data);
    },
    changeAiSettingChatgptModel(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingChatgptModel', data);
    },
    changeAiSettingChatgptApi(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingChatgptApi', data);
    },
    changeAiSettingTyqw(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingTyqw', data);
    },
    changeAiSettingTyqwModel(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingTyqwModel', data);
    },
    changeAiSettingTyqwApi(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingTyqwApi', data);
    },
    changeAiSettingMidjourneyV6(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingMidjourneyV6', data);
    },
    changeAiSettingMidjourneyV6Api(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingMidjourneyV6Api', data);
    },
    changeAiSettingMidjourneyV6Size(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingMidjourneyV6Size', data);
    },
    changeAiSettingMidjourneyV6Num(data) {
      // 使用 mutations 来修改 select 属性
      this.$store.commit('setAiSettingMidjourneyV6Num', data);
    },
  },
};
</script>


<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/variable.css';


.content-bg {
  width: 740.0006px;
  height: 811.0003px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15.9994px 20.0006px;
}

.content {
  width: 100%;
  height: calc(811.0003px - 32.0006px);
  display: flex;
  flex-direction: column;
  gap: 3.9994px;
}

.scrollable-container {
  max-height: calc(811.0003px - 32.0006px) !important;
  /* 设置最大高度为5个item的高度 */
  overflow-y: auto;
  overflow-x: hidden;
  /* 当内容超出容器高度时，显示垂直滚动条 */
}

.content .item {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 3.9994px;
}

.item .item-top {
  width: 100%;
  height: 64.9997px;
  font-family: 'LatoR';
  font-size: 24px;
  color: #3D3D3D;
  font-weight: bolder;
  display: flex;
  padding: 9.9994px 9.9994px;
  border-width: 0px 0px 1.0003px 0px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.item .item-bg {
  width: 100%;
  height: calc(100% - 64.9997px - 3.9994px);
  padding: 24px 24px;
  display: flex;
  justify-items: center;
  align-items: center;
}

.item-bg .item-bg-content {
  width: 100%;
  height: auto;
  border-radius: 19.0003px;
  display: flex;
  flex-direction: column;
  border: 1.0003px solid rgba(23, 24, 25, 0.3);
}

.item-bg-content .item-bg-content-item {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 9.9994px 20.0006px;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.item-bg-content .item-bg-content-item {
  border-top: 1.0003px solid rgba(23, 24, 25, 0.3);
}

.item-bg-content .item-bg-content-item:nth-of-type(1) {
  border: none !important;
}

.item-bg-content-item .item-bg-content-item-text {
  font-size: 14.0006px;
  font-family: 'LatoR';
  font-weight: bolder;
  color: #3D3D3D;
  font-size: 14.0006px;
}

.item-bg-content-item .item-bg-content-item-content {
  width: 270px;
  height: 36px;
  border: 1.0003px solid rgba(23, 24, 25, 0.3);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  gap: 49.0003px;
  padding: 0 14.0006px;
  border-radius: 6px;
}

.item-bg-content-item-content .item-bg-content-item-select {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  border: none;
  outline: none !important;
}



.item-bg-content-item-select option {
  width: 100%;
  user-select: none;
  font-family: 'LatoR';
  font-size: 12px;
  color: #3D3D3D;
}

.item-bg-content-item-select option:hover {
  background-color: #67514e !important;
}

.password-input-short {
  width: 96px !important;
}

.password-input-readonly {
  background-color: #f2f2f2;
  cursor: not-allowed;
}



.content-bg .footer {
  width: 100%;
  height: 39.9994px !important;
  /* background: #000;   */
  display: flex;
  flex-direction: row;
  padding: 28.9594px 24px;
  gap: 20.0006px;
  justify-content: right;
  align-items: center;
}

.footer .save {
  width: 80.0006px;
  height: 32.0006px;
  background: #c2553a;
  color: #fff;
  font-family: 'SYHT-B';
  font-size: 12px;
  line-height: 32.0006px;
  text-align: center;
  border-radius: 3.9994px;
  cursor: pointer;
  transition: all .3s ease-in;
  box-shadow: 0vw .0521vw .1563vw 0vw rgba(0, 0, 0, 0.3);
}

.footer .save:hover {
  background: #8e3a2f;
  box-shadow: none;
}

.footer .cancel {
  width: 80.0006px;
  height: 32.0006px;
  background: #909399;
  color: #fff;
  font-family: 'SYHT-B';
  font-size: 12px;
  line-height: 32.0006px;
  text-align: center;
  border-radius: 3.9994px;
  cursor: pointer;
  transition: all .3s ease-in;
  box-shadow: 0vw .0521vw .1563vw 0vw rgba(0, 0, 0, 0.3);
}

.footer .cancel:hover {
  background: #4f4f50;
  box-shadow: none;
}
</style>
