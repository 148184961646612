<template>
    <div class="foot-con">
        <div class="content">
            <p class="text1">Copyright © 2022-2023 FastPage 网站 保留所有权利 ICP证：<span><a
                        href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
                        target="_blank">粤ICP备2022142515号-1</a></span>
            </p>
            <p class="text2">(●'◡'●)ﾉ 网站稳定运行：<span class="day">342</span> 天 <span class="hour">22</span> 时 <span
                    class="min">34</span> 分 <span class="sec">31</span> 秒</p>
        </div>
    </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted } from 'vue'
export default {
    name: 'FooterView',
    setup() {
        // console.log('1-开始创建组件-setup')
        const data = reactive({})
        onBeforeMount(() => {
            // console.log('2.组件挂载页面之前执行----onBeforeMount')
        })
        onMounted(() => {
            // console.log('3.-组件挂载到页面之后执行-------onMounted')
        })
        return {
            ...toRefs(data),
        }
    },
}

</script>

<style scoped lang='css'>
.foot-con {
    width: 1920px;
    height: 59.401px;
    background: rgba(255, 255, 255, 0.95);
    /* background: linear-gradient(to bottom, rgba(252, 252, 252, 0.9) 0%, rgba(252, 252, 252, 0.95) 20%, rgba(252, 252, 252, 1) 100%); */
    backdrop-filter: blur(15.7594px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.foot-con .content {
    width: 564.1997px;
    /* background: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'AlibabaSansR';
    font-size: 12.1594px;
    /* font-size: calc(0.625 * var(--font-size-base)); */
    color: #3d3d3d97;
    display: block;
    text-align: center;
}


.content p span,
a {
    color: #b5514d;
}
</style>