<template>
  <div class="main-con">
    <div class="content">
      <div class="top">
        <span>喜加一({{ filteredGameLists.length }})</span>
      </div>
      <div class="bottombg">
        <div class="bottom">
          <div class="scrollable-container">
            <div class="item" v-for="(item, index) in filteredGameLists" :key="index">
              <div class="left">
                <img :src="item.pictureUrl" alt="Don't Shout Together">
              </div>
              <div class="right">
                <p class="name">{{ item.gameName }}</p>
                <div class="time">
                  <span>{{ filterTime(item.computedResult) }}</span>
                </div>
                <p class="intro">
                  {{ item.gameDesc }}
                </p>
                <div class="btn" @click="openExternalLink(item.getUrl)">
                  <span>领取</span>
                </div>
              </div>
            </div>
            <div class="item"></div>
            <div class="item"></div>
            <div class="item"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/http/request';
import { notificationTip } from '../utils/messageTip.js'

export default {
  name: 'GameXJYContentView',

  data() {
    return {
      game_lists: [],
      intervalId: null
    };
  },
  mounted() {
    // let endTime = 1719388799000;
    // console.log("当前时间:", this.formatTimeInEast8(Date.now()));
    // console.log("截止时间:", this.formatTimeInEast8(endTime));
    // console.log("filterTime(endTime)", this.filterTime(endTime));
    this.getGameList()
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  computed: {
    filteredGameLists() {
      return this.game_lists;
    }
  },
  methods: {
    async getGameList() {
      let res = await request.get("/api/game/list");
      console.log("res", res);
      if (res && res.code === 200) {
        console.log(res.data.data);
        this.game_lists = res.data;
      } else {
        notificationTip("获取最新喜加一数据", "数据获取失败!", "error")
      }
    },
    openExternalLink(link) {
      window.open(link, '_blank');
    },
    isOverdue(currentTime, endTime) {
      return currentTime > endTime;
    },
    filterTime(endTime) {
      // Ensure endTime is in milliseconds
      if (endTime < 10000000000) {
        endTime = endTime * 1000;
      }

      let now = Date.now();
      let remainingTime = endTime - now;

      if (remainingTime <= 0) {
        return "已错过免费领取时间";
      }

      // Calculate days, hours, minutes, seconds, and milliseconds
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      const milliseconds = remainingTime % 1000;

      return `免费领取剩余: ${days}天${hours}时${minutes}分${seconds}秒`;
    },
    formatTimeInEast8(timeInMillis) {
      return new Date(timeInMillis).toLocaleString("zh-CN", { timeZone: "Asia/Shanghai" });
    }
  }

};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';

.main-con {
  width: 1920px;
  height: calc(100vh - 56.0006px);
  background: rgb(255 241 241 / 52%);
  backdrop-filter: blur(7.0003px);
  font-family: 'LatoR';
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-con .content {
  width: 933.9994px;
  height: 76vh;
  padding: 10.9997px 307.0003px;
  background: #fff;
  border-radius: 37.0003px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15.9994px 24px;
  overflow: hidden;
  padding-bottom: 5vh;
}

.content .top {
  width: 100%;
  padding: 15.9994px 0 15.9994px 39.9994px;
  border-bottom: 1.9603px solid #EEEEEE;
}

.top span {
  font-size: 24px;
  color: #3D3D3D;
}

.content .bottombg {
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.bottombg .bottom {
  width: 100%;
  height: 100%;
  padding-top: 9.9994px;
  padding-left: 9.9994px;
}

.bottom .item {
  width: 783.9994px;
  height: 237.9994px;
  display: flex;
  flex-direction: row;
  gap: 36px;
  padding: 24px 0;
  padding-left: 44.0006px;
}

.scrollable-container {
  max-height: 1152px !important;
  overflow-y: auto;
}

.item .left {
  width: 423.001px;
  height: 100%;
  box-shadow: 0 1.92px 6.1632px 0 rgba(0, 0, 0, 0.109);
  border-radius: 1.92px;
}

.left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 7.68px solid #fff;
}

.item .right {
  width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1.0003px solid #efefef;
}

.right p.name {
  font-size: 20.0006px;
  font-family: 'LatoB';
}

.right .time {
  font-size: 14.0006px;
  color: #fff;
}

.time span {
  display: inline-block;
  background: #0078F2;
  border-radius: 2.9203px;
  border-top-left-radius: 7.68px;
  border-bottom-right-radius: 7.68px;
  padding: 4.8403px 4.8403px;
}

.right .intro {
  font-size: 14.0006px;
  color: #ADB5BD;
  width: 333.001px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
}

.right .btn {
  width: 138.1997px;
  height: 27.001px;
  background: #00B15B;
  color: #fff;
  font-size: 14.0006px;
  text-align: center;
  line-height: 27.001px;
  border-radius: 24px;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;
}

.right .btn:hover {
  box-shadow: 0px 3.001px 3.001px 0px rgba(0, 0, 0, 0.3);
}
</style>
