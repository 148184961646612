<template>
  <div class="content-bg">
    <div class="content">
      <TableXjyComponent :tableXjyDataList="tableXjyListCom" @dialogVisible="handleDialogVisible"
        @editForm="handleEditForm" @delete="deleteRow" />
      <div v-if="dialogVisible" class="dialog-overlay">
        <div class="dialog">
          <div class="title">
            更新数据
          </div>
          <div class="items">
            <div class="item">
              <label>
                GameName:
              </label>
              <input v-model="editForm.gameName" />
            </div>
            <div class="item">
              <label>
                EndTime:
              </label>
              <input v-model="editForm.endTime" />
            </div>
            <div class="item">
              <label>
                PictureUrl:
              </label>
              <input v-model="editForm.pictureUrl" />
            </div>
            <div class="item">
              <label>
                getUrl:
              </label>
              <input v-model="editForm.getUrl" />
            </div>
            <div class="item">
              <label>
                gameDesc:
              </label>
              <input v-model="editForm.gameDesc" />
            </div>
          </div>

          <div class="btn">
            <button class="custom-button" @click="saveEdit">Save</button>
            <button class="custom-button" @click="dialogVisible = false">Cancel</button>
          </div>
        </div>
      </div>

      <div v-if="dialogVisibleAdd" class="dialog-overlay dialog-add">
        <div class="dialog">
          <div class="title">
            缺失数据补充
          </div>
          <div class="items">
            <div class="item">
              <label>
                游戏名称:
              </label>
              <input v-model="xjyAddForm.gameName" />
            </div>
            <div class="item">
              <label>
                结束时间:
              </label>
              <input v-model="xjyAddForm.endTime" />
            </div>
            <div class="item">
              <label>
                图片地址:
              </label>
              <input v-model="xjyAddForm.pictureUrl" />
            </div>
            <div class="item">
              <label>
                领取地址:
              </label>
              <input v-model="xjyAddForm.getUrl" />
            </div>
            <div class="item">
              <label>
                游戏介绍:
              </label>
              <input v-model="xjyAddForm.gameDesc" />
            </div>
          </div>

          <div class="btn">
            <button class="custom-button" @click="saveEditAdd">Save</button>
            <button class="custom-button" @click="dialogVisibleAdd = false">Cancel</button>
          </div>
        </div>
      </div>

      <div class="addBtn">
        <button @click="dialogVisibleAdd = true">数据补充</button>
      </div>
    </div>
  </div>
</template>

<script>
import TableXjyComponent from '../../components/TableXjyComponent.vue';

import request from '@/http/request';
import { notificationTip } from '../../utils/messageTip.js'

import { mapState } from 'vuex';


export default {
  name: 'DataXjyContentView',
  components: {
    TableXjyComponent
  },
  computed: {
    computedDataList() {
      const dataList = this.tableDataList[this.dataSelect]
      console.log("dataList", dataList);
      return dataList;
    }
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleAdd: false,
      dataSelect: 0,
      xjyEditForm: {
        id: '',
        gameName: '',
        beginTime: '',
        endTime: '',
        getUrl: '',
        pictureUrl: '',
        gameDesc: '',
        isChinese: '',
        beginTimeFormat: '',
        endTimeFormat: ''
      },
      xjyAddForm: {
        id: '',
        gameName: '',
        beginTime: '',
        endTime: '',
        getUrl: '',
        pictureUrl: '',
        gameDesc: '',
        isChinese: ''
      },
      tableDataList: {
        "xjy": []
      },
      isDataLoaded: false  // 数据加载标志位
    }
  },
  mounted() {
    this.getTableListDataByAxios();
  },
  watch: {
    '$store.state.dataSelect': {
      handler(newValue) {
        this.dataSelect = newValue
      },
      immediate: true
    },
    '$store.state.dataParentSelect'(newValue, oldValue) {
      if (newValue === 2) {
        this.getTableListDataByAxios();
      }
    },
    '$store.state.select'(newValue, oldValue) {
      if (newValue === 4) {
        this.getTableListDataByAxios();
      }
    }
  },
  computed: {
    ...mapState({
      rightItemsContentSelect: state => state.rightItemsContentSelect
    }),
    tableXjyListCom() {
      // 首先检查 this.rightItemsContentSelect 是否有效
      if (!this.rightItemsContentSelect || !this.tableDataList[this.rightItemsContentSelect]) {
        // 如果不存在有效的 this.rightItemsContentSelect 或者对应的数组，则返回空数组或者做出其他适当的处理
        return [];
      }
      // 返回根据 this.rightItemsContentSelect 选择的数组数据
      return this.tableDataList[this.rightItemsContentSelect];
    }
  },
  methods: {
    async getTableListDataByAxios() {
      let res = await request.get("/api/game/list")
      console.log("res", res);

      if (res && res.code === 200) {
        this.tableDataList[this.rightItemsContentSelect] = res.data;
        this.isDataLoaded = true;  // 数据加载成功
      } else {
        notificationTip("获取最新喜加一数据", "数据获取失败!", "error")
      }
    },
    toggleDialog() {
      this.dialogVisible = !this.dialogVisible;
    },
    async deleteRow(itemId) {
      const url = '/api/game/delete/' + itemId;
      let res = await request.delete(url)
      console.log("del-res", res);

      if (res && res.code === 200) {
        this.getTableListDataByAxios();
      } else {
        notificationTip("删除喜加一游戏数据", "删除失败!", "error")
      }

    },
    async updateRow() {
      let res = await request.put("/api/game/update", this.editForm)
      console.log("edit-res", res);

      if (res && res.code === 200) {
        this.getTableListDataByAxios();
      } else {
        notificationTip("修改喜加一游戏数据", "修改失败!", "error")
      }

    },
    handleDialogVisible(dialogVisible) {
      this.dialogVisible = dialogVisible;
    },
    handleEditForm(editForm) {
      this.editForm = editForm
    },
    saveEdit() {
      this.updateRow()
      this.dialogVisible = false;
    },
    async saveEditAdd() {
      if (this.xjyAddForm.beginTime) {
        let beginTime = new Date(this.xjyAddForm.beginTime);
        // 将日期时间转换为东八区时间（北京时间）
        beginTime.setHours(beginTime.getHours() + 8);
        this.xjyAddForm.beginTime = beginTime.toISOString();
      }

      if (this.xjyAddForm.endTime) {
        let endTime = new Date(this.xjyAddForm.endTime);
        // 将日期时间转换为东八区时间（北京时间）
        endTime.setHours(endTime.getHours() + 8);
        this.xjyAddForm.endTime = endTime.toISOString();
      }

      let res = await request.post("/api/game/add", this.xjyAddForm)
      console.log("add-res", res);

      if (res && res.code === 200) {
        this.dialogVisibleAdd = false
        notificationTip("新增数据", "数据添加成功!", "success")
      } else {
        notificationTip("新增数据", "数据添加失败!", "error")
        this.dialogVisibleAdd = false
      }
    }
  }
};
</script>

<style scoped>
.content-bg {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 44.0006px;
  /* Apply blur effect */
}

.content-bg .content {
  height: 100%;
  position: relative;
}

.content .addBtn {
  position: absolute;
  bottom: 9.9994px;
  right: 3.9206px;
  height: 27.001px;
  font-size: 14.0006px;
}

.addBtn button {
  padding: 8.0006px 15.9994px;
  border: none;
  border-radius: 3.9994px;
  background-color: #5b3442;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addBtn button:hover {
  background-color: #0056b3;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* 确保遮罩层在对话框之上 */
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20.0006px;
  border-radius: 8.0006px;
  box-shadow: 0 2.0006px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 288px;
  max-width: 80%;
  /* 控制对话框最大宽度 */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 17.8406px;
}

.dialog .title {
  font-size: 15.9994px;
  color: #3D3D3D;
  font-family: 'SYHT-R';
  font-weight: bolder;
}

.dialog .items {
  width: 100%;
  display: flex;
  gap: 15.9994px;
  flex-direction: column;
}

.dialog .item {
  width: 100%;
  height: 30px;
  display: flex;
  gap: 15.9994px;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
}

.item label {
  display: block;
  margin-bottom: 9.9994px;
  line-height: 30px;
  font-family: 'SYHT-R';
}

.dialog input {
  width: 184.9997px;
  /* 减去padding */
  padding: 8.0006px;
  font-size: 1em;
  border: 1.0003px solid #ccc;
  border-radius: 3.9994px;
  box-sizing: border-box;
  outline: none;
}

.dialog .btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn button {
  height: 100%;
  padding: 8.0006px 15.9994px;
  font-size: 14.0006px;
  border: none;
  border-radius: 3.9994px;
  background-color: #5b3442;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog button:hover {
  background-color: #0056b3;
}
</style>