<template>
    <div class="main-con">
        <div class="right">
            <div class="item" v-for="item in rightItems" :key="item.id">
                <div class="item-title">
                    {{ item.title }}
                </div>
                <div class="item-content">
                    <div class="item-content-item" v-for="ItemC in item.content"
                        :class="{ 'item-content-item-active': this.$store.state.dataSelect === ItemC.id }"
                        @click="active(ItemC.id, item.path, item.id, ItemC.selectContent)">
                        {{ ItemC.content }}
                    </div>
                </div>
            </div>

        </div>
        <div class="left">
            <router-view v-slot="{ Component }">
                <transition name="fade" mode="out-in">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import request from '@/http/request';
import { notificationTip } from '../utils/messageTip.js'

export default {
    name: 'DataManagerView',

    data() {
        return {
            select: 0,
            rightItems: [
                {
                    "id": 0,
                    "title": "快捷启动栏",
                    "path": "/data/common",
                    "content": [
                        {
                            "id": 0,
                            "content": "常用(Common)",
                            "selectContent": "common"
                        },
                        {
                            "id": 1,
                            "content": "开发(development)",
                            "selectContent": "development"
                        },
                        {
                            "id": 2,
                            "content": "人工智能(ai)",
                            "selectContent": "artificial_Intelligence"
                        },
                        {
                            "id": 3,
                            "content": "设计(design)",
                            "selectContent": "design"
                        },
                        {
                            "id": 4,
                            "content": "生活(lifestyle)",
                            "selectContent": "lifestyle"
                        },
                        {
                            "id": 5,
                            "content": "游戏(game)",
                            "selectContent": "game"
                        },
                    ]
                },
                {
                    "id": 1,
                    "title": "AI助手",
                    "path": "/data/ai",
                    "content": [
                        {
                            "id": 0,
                            "content": "设置",
                            "selectContent": "setting"
                        },
                        {
                            "id": 1,
                            "content": "自定义",
                            "selectContent": "zdy"
                        },
                    ]
                },
                {
                    "id": 2,
                    "title": "喜加一",
                    "path": "/data/xjyData",
                    "content": [
                        {
                            "id": 0,
                            "content": "全平台",
                            "selectContent": "xjy"
                        },
                    ]
                },
            ]
        };
    },

    mounted() {
        this.getTableListDataByAxios()
    },
    methods: {
        async getTableListDataByAxios() {
            let res = await request.get("/api/tab/list");
            console.log("res", res);

            if (res && res.code === 200) {
                console.log("response.data", res.data);
                this.$store.commit('setTableDataList', res.data);
                this.$store.commit('setIsDataLoaded', true);
            } else {
                notificationTip("获取快捷启动栏数据", "数据获取失败!", "error")
            }

        },
        changeRightItemsContentSelect(data) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setRightItemsContentSelect', data);
        },
        active(cid, path, pid, itemSelect) {
            this.changeSelect(cid, pid)
            // 使用$router.push来进行路由跳转
            this.$router.push(path);
            // 阻止a标签默认的href跳转行为

            //设置选中的选项
            this.changeRightItemsContentSelect(itemSelect)
            return false;
        },
        changeSelect(cid, pid) {
            // 使用 mutations 来修改 select 属性
            this.$store.commit('setDataSelect', cid);
            this.$store.commit('setDataParentSelect', pid);
        },
    },
};
</script>

<style scoped>
@import '../assets/css/common.css';
@import '../assets/css/variable.css';


/* 滚动条 */
/* 修改滚动条的宽度 */
::-webkit-scrollbar {
    width: 6.24px;
}

/* 修改滚动条的背景色 */
::-webkit-scrollbar-track {
    background: #212121;
}

/* 修改滚动条滑块的颜色 */
::-webkit-scrollbar-thumb {
    background: #383838;
}

/* 修改滚动条滑块的颜色（鼠标悬停时） */
::-webkit-scrollbar-thumb:hover {
    background: #383838;
}


.main-con {
    width: 1920px;
    height: calc(100vh - 56.0006px);
    background: rgb(255 241 241 / 52%);
    backdrop-filter: blur(7.0003px);
    display: flex;
    flex-direction: row;
    backdrop-filter: blur(7.0003px);
    /* Apply blur effect */
}



.main-con .right {
    background: #fff;
    width: 266.0006px;
    display: flex;
    flex-direction: column;
    padding: 48px 32.0006px;
    gap: 20.0006px;
}

.right .item {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.item .item-title {
    font-size: 20.0006px;
    color: #3D3D3D;
    font-family: "LatoB";
    font-weight: bold;
    padding: 0 9.9994px;

}

.item .item-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 9.9994px;
}

.item-content .item-content-item {
    width: 100%;
    padding: 9.9994px 15.9994px;
    font-size: 16.9997px;
    font-family: 'SYHT-R';
    cursor: pointer;
    transition: color .3s ease-in-out;
    color: #606266;
}

.item-content .item-content-item:hover {
    color: #94525E;
}

.item-content-item-active {
    color: #94525E !important;
}

.main-con .left {
    width: calc(1920px - 266.0006px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 0 32.0006px;
}

.left-isTopPadding {
    padding-top: 44.0006px;
}
</style>